
import { useLocation,useNavigate,useParams, Link } from "react-router-dom"
import Catalogv2 from "./Catalogv2"
import notFound from "../assets/images/page-404.webp";

function Anythingv2() {
  const location = useLocation()
  const history = useNavigate()
const Catid = useParams().id.split("=")[1]
const path  = location.pathname
let name ;
if (location.pathname.indexOf("c=") > 0) name = "category"
if (location.pathname.indexOf("m=") > 0) name = "manufacturer"
if (location.pathname.indexOf("s=") > 0) name = "seller" 
if(Catid){
    return (
        <div className="h-full">
            {Catid && 
            <Catalogv2 id={Catid} type={name} />
        }
        </div>
    )
}
return (
    <div className="flex items-center justify-center mt-20 pb-5 flex-col">
      <img src={notFound} className=" w-2/12" alt="Not Found" />
      <h2 className="text-2xl mt-4">Sorry, there is nothing here!</h2>
      <Link
        to={`${path}/`}
        className="bg-dblue text-white px-10 py-3 rounded mt-4"
      >
        START SHOPPING
      </Link>
      <button
        onClick={() => history.goBack()}
        className="bg-dbase text-white px-10 py-3 rounded mt-4"
      >
        Go back
      </button>
    </div>
  );
}

export default Anythingv2
