import React from "react";

function SingleProductExport(props) {
  return (
    <div className="">
      <img src={props.item.thumb} />
      <div> {props.item.name} </div>
      <div>
        <p
          className={`text-dblack ${
            props.isList ? "text-xl" : "text-d14 md:text-base"
          } md:w-full font-bold `}
        >
          {props.item.special !== "0" &&
          props.item.special !== "" &&
          props.item.special !== false
            ? props.item.special
            : props.item.price}
        </p>
        <div
          className={`${!props.item.special && "invisible"} flex items-center`}
        >
          <p
            className={`text-dgrey1 text-d12 line-through  ${
              (props.item.special === "0" ||
                props.item.special === "" ||
                props.item.special === false) &&
              "invisible"
            }`}
          >
            {props.item.price}
          </p>
        </div>
      </div>

      <div className="my-1 px-1 flex justify-between z-10">
        <span
          className={`p-1 `}
        >
          {" "}
          {props.item.sku}
        </span>
        <span className="bg-dgrey1 bg-opacity-25 p-1 rounded">
          {props.item.quantity}
        </span>
      </div>
    </div>
  );
}

export default SingleProductExport;
