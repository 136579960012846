

function PointsLoaderWhite() {

    // Set loader color to white by default
    const mystyle = {
      backgroundColor: "#FFFFFF",
    };
  
    return (
  <div class="lds-ellipsis-white"><div></div><div></div><div></div><div></div></div>
    );
  }
  
  export default PointsLoaderWhite;
  