import { AccountContext } from "../contexts/AccountContext";
import { useContext, useState } from "react";




export  const   HandleAuthForm=()=>{

    const [state, dispatch] = useContext(AccountContext);
      const [type,setType] = useState('loginPhoneOtp')


    const openAuthForm=(type)=>{

      setType(type)
      dispatch({ type: "setisRegistrationFormOpen", payload: true });
    }

    const closeAuthForm =()=>{
      setType('default')
      dispatch({ type: "setisRegistrationFormOpen", payload: false });
    
    }
  



    return {openAuthForm,closeAuthForm,type,setType}
}

