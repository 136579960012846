import { Spinner } from "../components/spinnerLoaderMotion";
import buildLink, { path } from "../urls";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsFilter, BsSearch } from "react-icons/bs";
import { CgEye } from "react-icons/cg";
import { IoReload } from "react-icons/io5";
import { MdOutlineRateReview, MdOutlineReplay } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import _axios from "../axios";
import ThreeDotsWave from "../components/loaderMotion";
import useDeviceSize from "../components/useDeviceSize";
import { useLocation } from "react-router";
import UserSidebar from "../components/UserSidebar";
import { AccountContext } from "../contexts/AccountContext";
function OrdersTest() {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [width, height] = useDeviceSize();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const status = params.get("status") || 0;
  const hasStatus = params.has("status"); 
  const status_id = status || 0;
  const [loadingReorder, setLoadingReorder] = useState(null);
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AccountContext);
  const [noOrderstatus, setNoOrderStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  
  useEffect(() => {
    if (hasStatus) {
      setNoOrderStatus(false);
      if (!state.loading && !state.loged) {
        navigate("/");
      } else if (state.loged) {
        fetchOrders(currentPage, status); 
      }
    } else {
      setNoOrderStatus(true);
    }
  }, [state.loading, currentPage, hasStatus, status]);


  const [statuses, setStatuses] = useState([
    { id: "all", name: "All", isActive: true },
    { id: "proccessing", name: "Processing", isActive: false },
    { id: "completed", name: "Completed", isActive: false },
    { id: "trash", name: "Canceled", isActive: false },
  ]);
  const handleStatusClick = (id) => {
    navigate("?status=" + id);
  };
  const fetchOrders = async (currentPage = 1) => {
    setLoading(true);
    try {
      const resp = await _axios.get(
        buildLink("orders") +
          `&status_id=${status_id}&status=${status}&page=${currentPage}&limit=10`
      );
      console.log(resp);
      setOrders(resp.data.data.orders);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const reOrder = (order_id) => {
    // setLoading(true);
    setLoadingReorder(order_id);

    let obj = {
      order_id,
      source: "website",
    };

    _axios
      .post(buildLink("reorder"), obj)
      .then((response) => {
        if (response.data.success) {
          toast(response.data.message, { type: "success" });
          // setGlobalMessage(response.data.message);
          navigate(`${path}/cart`);
        } else {
          toast(response.data.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.response.data.message, { type: "error" });
      })
      .finally(() => {
        setLoadingReorder(null);
      });
  };

  useEffect(() => {
    setStatuses((prevStatuses) =>
      prevStatuses.map((status) => ({
        ...status,
        isActive: status.id == status_id, // Set isActive to true only for the clicked status
      }))
    );
    fetchOrders();
  }, [status_id]);

  const getStatusClass = (status) => {
    switch (status) {
      case "Shipped":
        return "text-dgreen bg-dgreen bg-opacity-20 "; // Green for Shipped
      case "Pending":
        return "text-dyellow bg-dyellow bg-opacity-20 "; // Yellow for Pending
      case "Processing":
        return "text-dblue  bg-dblue bg-opacity-20 "; // Blue for Processing
      case "Cancelled":
        return "text-dbase bg-dbase bg-opacity-20"; // Red for Cancelled
      case "Delivered":
        return "text-[#008080] bg-[#008080] bg-opacity-20"; // Teal for Delivered
      default:
        return "text-gray-500"; // Default gray color
    }
  };

  return (
    <div>
      <title>My Account | ishtari</title>

      <div className="flex flex-row">
      <div
        className={`w-full mb-4 md:w-1/5  -ml-3 ${
          width <= 650 ? "hidden" : ""
        }`}
      >
            <UserSidebar />
          </div>

        <div className=" w-full h-full p-5 max-md:py-5 max-md:px-2  flex flex-col gap-3  ">
          <div className="flex flex-col justify-start items-start">
            <h1 className="text-2xl font-bold">My Orders</h1>
          </div>
          <div className="flex flex-col items-center bg-white w-full h-20 rounded-md mx-auto">
            <div className="flex items-center gap-2 w-full max-w-lg my-auto px-5 max-md:px-2">
              {/* Scrollable Container */}

              <div className="flex gap-2 overflow-x-auto scrollbar-hide w-full items-center border-opacity-40 border-4 border-[#e7e7e7] bg-[#e7e7e7] bg-opacity-25 rounded-lg h-fit  ">
                {statuses.map((status) => {
                  return (
                    <button
                      onClick={() => handleStatusClick(status.id)}
                      className={`py-2 ${
                        status.isActive ? "bg-white" : "text-dgreySeller"
                      }  font-semibold w-32 text-sm rounded-lg px-2 whitespace-nowrap`}
                    >
                      {status.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className=" flex flex-row bg-white w-full h-20 max-md:h-fit max-md:py-5 rounded-md px-10 max-md:px-2 justify-start ">
            <div className=" w-full max-md:flex-col  h-fit overflow-hidden relative  my-auto flex justify-between gap-2 ">
              <div className=" border-opacity-40 border border-[#d5d5d5]  max-md:w-full  w-[40%] bg-[#e7e7e7] bg-opacity-25   rounded-lg ">
                <BsSearch className=" absolute left-3 top-[0.65rem] text-xl text-dgrey1 " />
                <input
                  placeholder=" Search"
                  className=" placeholder:text-dgreySeller  w-full py-2 pl-10  bg-transparent  outline-none "
                />
              </div>
              <div className=" flex justify-end max-md:justify-between  gap-2 ">
                <div className="  flex flex-row gap-2 justify-center px-3 border-opacity-40 border border-[#d5d5d5]   bg-[#e7e7e7] bg-opacity-25   rounded-lg ">
                  <BsFilter className=" my-auto" />
                  <span className=" my-auto">Filter</span>
                </div>
                <div className="   flex flex-row gap-2 justify-center px-3 border-opacity-40 border border-[#d5d5d5]   bg-[#e7e7e7] bg-opacity-25   rounded-lg">
                  <input
                    type="date"
                    className=" border-none outline-none bg-transparent py-2 "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-row bg-white  rounded-md w-full justify-center mx-auto ">
            {loading ? (
              <div className="py-10">
                <ThreeDotsWave />
              </div>
            ) : (
              <div className="container mx-auto px-4 py-4 max-md:px-2 ">
                {width > 650 ? (
                  <div className=" overflow-x-auto">
                    <div className=" w-full ">
                      <table className="w-full">
                        <thead className=" text-dgrey1 border-b border-dplaceHolder bg-gray-100">
                          <tr>
                            <th className="px-6 py-3 text-center">Orders</th>
                            <th className="px-6 py-3 text-center">Date</th>
                            <th className="px-6 py-3 text-center">Products</th>
                            <th className="px-6 py-3 text-center">Payment</th>
                            <th className="px-6 py-3 text-center">Status</th>
                            <th className="px-6 py-3 text-center">Price</th>
                            <th className="px-6 py-3 text-center text-dblack pr-semibold">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders &&
                            orders.map((order, index) => (
                              <tr
                                key={index}
                                className="border-b border-dplaceHolder"
                              >
                                <td className="px-6 py-4 text-center">
                                  <div className=" flex flex-col gap-1">
                                    <span className=" text-dgreyProduct text-sm">
                                      #{order.order_id}
                                    </span>
                                    <span>{order.region}</span>
                                  </div>
                                </td>
                                <td className="px-6 py-4 text-center text-dgreyQtyProduct text-sm">
                                  {order.date_added}
                                </td>
                                <td className="px-6 py-4 text-center">
                                  {order.products}
                                </td>
                                <td className="px-6 py-4 text-center">
                                  <div className=" flex flex-col gap-2 justify-center text-center">
                                    <div className=" w-8 mx-auto">
                                      <img src={order.payment_method_image} />
                                    </div>
                                    <h2 className=" text-dblack text-sm">
                                      {order.payment_method}
                                    </h2>{" "}
                                  </div>
                                </td>
                                <td className={`px-2 py-4 text-center `}>
                                  {" "}
                                  <span
                                    className={`${getStatusClass(
                                      order.status
                                    )} px-2 rounded-lg text-sm py-2`}
                                  >
                                    {order.status}
                                  </span>
                                </td>{" "}
                                {/* Dynamically styled status */}
                                <td className="px-6 py-4 text-center">
                                  {order.total}
                                </td>
                                <td className=" ">
                                  <div className=" flex justify-center gap-2">
                                    <Link
                                      href={`${path}/account/orders/${order.order_id}`}
                                    >
                                      <CgEye className=" my-auto" />
                                    </Link>
                                    {loadingReorder == order.order_id ? (
                                      <div className="  w-4 h-4">
                                        <Spinner />
                                      </div>
                                    ) : (
                                      <button
                                        onClick={() => reOrder(order.order_id)}
                                        className=""
                                      >
                                        <IoReload className=" my-auto" />
                                      </button>
                                    )}
                                    {order.status === "Processing" && (
                                      <Link
                                        href={`${path}/account/order-reviews/${order.order_id}`}
                                        className=""
                                      >
                                        <MdOutlineRateReview className=" my-auto" />
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {orders?.map((order) => (
                      <div
                        key={order.order_id}
                        className={`border flex flex-row justify-between border-[#d5d5d5]   border-opacity-50 rounded-lg p-4 
                                    `}
                      >
                        <div className="flex flex-col gap-1">
                          <h2 className="text-dblack text-lg">
                            Order # {order.order_id}
                          </h2>
                          <p className="text-dgrey1 text-sm">
                            Date: {order.date_added}
                          </p>
                          <p className="text-dgrey1 text-sm">
                            Products: {order.products}
                          </p>
                          <p className="text-dgrey1 text-sm">
                            Payment Method: {order.payment_method}
                          </p>
                          <p className="text-dgrey1 py-2 text-sm">
                            <span
                              className={`${getStatusClass(
                                order.status
                              )} px-2 rounded-lg text-sm py-2`}
                            >
                              {order.status}
                            </span>
                          </p>
                        </div>

                        <div className=" flex flex-col justify-between">
                          <p className="text-dgrey1  text-sm">
                            Total: {order.total}
                          </p>
                          <div className="flex justify-center gap-3">
                            <Link
                              href={`${path}/account/orders/${order.order_id}`}
                            >
                              <CgEye className=" my-auto" />
                            </Link>
                            {loadingReorder == order.order_id ? (
                              <div className="  w-4 h-4">
                                <Spinner />
                              </div>
                            ) : (
                              <button
                                onClick={() => reOrder(order.order_id)}
                                className=""
                              >
                                <IoReload className=" my-auto" />
                              </button>
                            )}

                            {order.status === "Complete" && (
                              <Link
                                href={`${path}/account/order-reviews/${order.order_id}`}
                                className=""
                              >
                                <MdOutlineRateReview className=" my-auto" />
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersTest;
