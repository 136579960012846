import { Helmet } from "react-helmet";

function SeoHandler(props) {
  const parser = new DOMParser();

  function parse(str) {
    if (str) {
      return str
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    } else {
      return window.config["seo-title"];
    }
  }
  const text = parser.parseFromString(
    `<!doctype html><body>${props.data.description}`,
    "text/html"
  ).body.textContent;

  return (
    <Helmet>
      <title>
        {parse(
          props.data.title?.charAt(0).toUpperCase() + props.data.title?.slice(1)
        ) + window.config["seo-title-base"]}
      </title>
      <meta name="description" content={text}></meta>
      <meta name="keyword" content={props.data.keyword}></meta>
      {/* <meta name="tag" content={props.data.tag}></meta> */}
      <meta
        property="og:title"
        content={
          parse(
            props.data.title?.charAt(0).toUpperCase() +
              props.data.title?.slice(1)
          ) + window.config["seo-title-base"]
        }
      />
      <meta property="og:description" content={props.data.description} />
      <meta property="og:image" content={props.data.imageURL} />
    </Helmet>
  );
}
export default SeoHandler;
