import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWindowDimensions } from "../components/TopHeader";

const SellerContext = createContext();

export const SellerProvider = ({ children }) => {
  const location = useLocation();
  const [isUserSeller, setIsUserSeller] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [smallMenuToggle, setSmallmenuToggle] = useState(true);
  const { width } = useWindowDimensions();

  const toggleMenu = () => {
    if (width > 1025) {
      setToggle(!toggle);
    } else {
      setSmallmenuToggle(!smallMenuToggle);
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith("/seller_report/")) {
      setIsUserSeller(true);
    } else {
      setIsUserSeller(false);
    }
  }, [location]);

  return (
    <SellerContext.Provider
      value={{
        isUserSeller,
        toggle,
        smallMenuToggle,
        toggleMenu,
        setIsUserSeller,
      }}
    >
      {children}
    </SellerContext.Provider>
  );
};

export const useSellerContext = () => useContext(SellerContext);
