import { Link } from "react-router-dom";
import { path } from "../urls";
import {BsFillCartCheckFill} from 'react-icons/bs'
import {MdAvTimer} from 'react-icons/md'
import { RiCoupon3Fill } from "react-icons/ri";
import { FaCheckCircle, FaTv } from "react-icons/fa";
function UserSidebarMobile(props) {
  return (
    <div className="flex md:block justify-between overflow-x-auto scrollbar-thin">
      <Link
        to={`${path}/account/orders`}
        className={`py-2 flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "orders" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="icon icon-dollar text-center  text-lg md:text-2xl w-8 "></i>
          </div>
          <span className="text-center text-d11">Orders</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/memberShip`}
        className={`py-2 flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "memberShip" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <FaTv className="text-center  text-lg md:text-2xl w-8 mt-1"/>
          </div>
          <span className="text-center text-d11 mt-2">MemberShip</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/coupons`}
        className={`py-2 flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "coupons" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <RiCoupon3Fill className="text-center  text-lg md:text-2xl w-8 mt-1"/>
          </div>
          <span className="text-center text-d11 mt-2">coupons</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/checkin`}
        className={`py-2 flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "checkin" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <FaCheckCircle className="text-center  text-lg md:text-2xl w-8 mt-1"/>
          </div>
          <span className="text-center text-d11 mt-2">checkin</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/buyagain`}
        className={`py-2  flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "buyagain" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
          <BsFillCartCheckFill className=" my-1 text-d16" />
          </div>
          <span className="text-center text-d11 mt-1">Buy Again</span>
        </div>
      </Link>

      <Link
        to={`${path}/account/recentlyViewed`}
        className={`py-2  flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "recentlyViewed" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
          <MdAvTimer className=" my-1 text-d16" />
          </div>
          <span className="text-center text-d11 mt-1">Recently Viewed</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/addresses`}
        className={`py-2 flex-row md:flex  items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "addresses" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="icon icon-location text-center text-lg md:text-2xl block w-8"></i>
          </div>
          <span className="text-center text-d11">Addresses</span>
        </div>
      </Link>
      <Link
        to={`${path}/account/wishlist`}
        className={`py-3 flex-row md:flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "wishlist" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="icon icon-heart text-md md:text-xl text-center w-8"></i>
          </div>
          <span className="text-center text-d11">WishList</span>
        </div>
      </Link>
      {/* <Link to={`${path}/account/notifications`} className={`py-2 flex-row md:flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${props.active === 'wishlist' && 'bg-white text-dbase'}`}>
            <div className="text-center"><i className="icon icon-mail text-md md:text-xl text-center w-8"></i></div>
                <span className="text-center text-d11">Notifications</span>
            </Link> */}
      <Link
        to={`${path}/account/profile`}
        className={`py-3 flex-row md:flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
          props.active === "profile" && "bg-white text-dbase"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="icon icon-user-solid text-md md:text-xl w-8 block text-center "></i>
          </div>
          <span className="text-center text-d11">Profile</span>
        </div>
      </Link>
    </div>
  );
}

export default UserSidebarMobile;
