import React from "react";
import { motion } from "framer-motion";

const styleContainer = {
  position: "relative",
  width:"100%",
  height:"100%"
};

const styleSpan = {
  display: "block",
  width: "100%",
  height: "100%",
  border: "3px solid #eee",
  borderTop: "3px solid #2D3134",
  borderRadius: "50%",
  boxSizing: "border-box",
  position: "absolute",
  top: 0,
  left: 0
};

const spinTransition = {
  repeat: Infinity,
  ease: "easeInOut",
  // width: ['100%', '50%'],
  duration: 1
};

export const Spinner = () => {
  return (
    <div style={styleContainer}>
      <motion.span
        style={styleSpan}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    </div>
  );
};
