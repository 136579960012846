import React from 'react'
import { useParams } from 'react-router-dom';
import Productv2 from './Productv2';
import Anythingv2 from './Anythingv2';

const Slug = () => {
    const { id } = useParams();
    const type = id.split('=')[0];
  return (
   <>{
      type === 'p'?<Productv2/>: 
      type === 'c' || type == 'm' || type == 's'? <Anythingv2/>:<></>
    
    
   }
    </>
  )
}

export default Slug