import _axios from "../axios";
import UserSidebar from "../components/UserSidebar";
import UserSidebarMobile from "../components/UserSidebarMobile";
import PointsLoader from "../components/PointsLoader";
import useDeviceSize from "../components/useDeviceSize";
import { CartContext } from "../contexts/CartContext";
import buildLink from "../urls";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import SingleProduct from "../components/SingleProduct";

function WishlistSimilar() {
  const [state, dispatch] = useContext(CartContext);
  const [width, height] = useDeviceSize();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { p } = useParams();

  useEffect(() => {
    function getSimilar() {
      if (p) {
        _axios
          .get(
            buildLink("similarWishlistProducts") +
              `&product_id=${p}&source_id=1`
          )
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching similar products:", error);
          });
      }
    }
    getSimilar();
  }, [p]);

  return (
    <div className="container text-dblack">
      <Helmet>
        <title>My Account | ishtari</title>
      </Helmet>
      <div>
        {state.loading ? (
          <div className="">
            <PointsLoader />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row">
            <div className="w-full mb-3 md:w-1/5">
              {width > 650 ? (
                <UserSidebar active={"wishlist"} />
              ) : (
                <UserSidebarMobile active={"wishlist"} />
              )}
            </div>
            <div className="w-full md:w-4/5 md:ml-5">
              <div className={`  ${width > 650 ? 'flex flex-wrap':'grid grid-cols-2'}   -mx-4`}>
                {data?.products?.map((product, index) => (
                  <div
                    key={product.product_id}
                    className={`w-full md:w-1/5 px-4 my-3`}
                  >
                    <SingleProduct item={product}></SingleProduct>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WishlistSimilar;
