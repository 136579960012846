import { StrictMode } from "react";
import { render } from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import "./config";
import { Suspense } from "react";
import { SellerProvider } from "./contexts/SellerContext";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

render(
  <StrictMode>
    <BrowserRouter >
      <SellerProvider>
      <GoogleOAuthProvider className="my-auto" clientId="912888796514-5ctiope0r15nvno9iik1vf8ko90qtva0.apps.googleusercontent.com">
        <Suspense fallback={<div></div>}>
          <App />
          </Suspense>
          </GoogleOAuthProvider >
       
      </SellerProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
