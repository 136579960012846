import { useReducer, createContext, useContext, useEffect, useState } from "react"

// import { AccountContext } from "./AccountContext";
import _axios from "../axios";
import buildLink from "../urls";


export const CurrencyContext = createContext()

export const CurrencyProvider = ({ children }) => {
    const [data, setData]= useState([]);
 
    // const [accountstate, dispatch ] = useContext(AccountContext)

    async function getInfo(){
         await _axios
        .get(buildLink("information", undefined, window.innerWidth))
        .then((response)=>{
            const data = response.data;
            if(data.success === true){
               setData(data);
            //    dispatch({ type: "setWhatsappNumbers", payload: data?.data?.whatsapp_number });
            } 
        })
    }

    useEffect(()=> {
        getInfo()
    },[])

    return (
        <CurrencyContext.Provider value={data}>
            {children}
        </CurrencyContext.Provider>
    )
}