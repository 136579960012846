import Cookies from "js-cookie";
import ReactPixel from "react-facebook-pixel";
import { useRef, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _axios from "../axios";
import Loader from "../components/Loader";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path, pixelID } from "../urls";
import { CartContext } from "../contexts/CartContext";
import { useWindowDimensions } from "../components/TopHeader";
import HandlePhoneModel from "../components/phoneHanlder";
import { Helmet } from "react-helmet";


// const script = document.createElement("script");
//         script.src = "https://test-bobsal.gateway.mastercard.com/checkout/version/61/checkout.js";
//         script.type="application/javascript"
//         script.async = true;
//         script.id = "simp-id";
//         document.body.appendChild(script);

function CheckoutV3() {
  const [state] = useContext(AccountContext);
  const [cartState, dispatch] = useContext(CartContext);
  const { width } = useWindowDimensions();
  // Is Phone Number Valid ?
  const [isValid, setIsValid] = useState(true);
  const sessionRef=useRef()
  const [err, setErr] = useState("");
  const history = useNavigate();
  // Customer id
  const [customerId, setCustomerId] = useState("0");
  const [firstnameState,setFirstnameState] = useState("")
  const [lastnameState,setLastnameState] = useState("")
  const [phoneState,setPhoneState] = useState("")
  const [addressState,setAddressState] = useState("")
  const [phoneNumber,setPhoneNumber] = useState()

  // Zones
  const [zones, setZones] = useState([]);
  const [firstAttemp, setFirstAttemp] = useState(true);
  // Cart states
  const [emptyCart, setEmptyCart] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  // Account states
  const [loged, setloged] = useState();
  const [emptyAddress, setEmptyAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmDisable, setConfirmDisalbe] = useState(false);
  // Manual
  const [manualResponse, setManualResponse] = useState({});
  const manualErrors = useRef({});
  const [manualCart, setManualCart] = useState([]);
  // User Details
  const [session,setSession] = useState()
  const firstname = useRef();
  
  const lastname = useRef("");
  const address_1 = useRef("");
  const address_2 = useRef("");
  const telephone = useRef("");
  const coupon = useRef("");
  // const [zone, setZone] = useRef({ name: "Beirut بيروت", value: "3969" });
  const zone = useRef({
    id: "3969",
    name: "Beirut بيروت"
  });

  const email = useRef("");
  const comment = useRef("");
  // address details
  const [addresses, setAddresses] = useState([]);
  const [activeAddress, setActiveAddress] = useState({});
  const [showAddresses, setShowAddresses] = useState(false);
  // const simpRef = useRef(null);
  // Initial checks
  useEffect(() => {
    if(firstAttemp){
    const fname = document.getElementById("firstname")
    fname?.focus()
  }
  })
  useEffect(() => {
    
    _axios
      .get(buildLink("get_account", undefined, window.innerWidth))
      .then((response) => {
        const data = response.data;
        if (!data.success) {
          setloged(false);
          getCart();
        } else {
          setloged(true);
          _axios
            .get(buildLink("login", undefined, window.innerWidth))
            .then((response) => {
              setCustomerId(response.data.customer_id);
            });
          _axios
            .get(buildLink("address", undefined, window.innerWidth))
            .then((response) => {
              if (!response.data.success) {
                history(
                  "/account/address/add?"+ "from-checkout=true"
                );
              } else {
                zone.current.name = response.data.data.addresses[0].city;
                zone.current.id = response.data.data.addresses[0].zone_id;

                setAddresses(response.data.data.addresses);
                changeAddress(response.data.data.addresses[0], false);
                getCart();
              }
            });
        }
      });
    // End account check

    // window.alertHello()

    
  }, []);

  // set active address from address list
  function changeAddress(address, _manual) {
    setShowAddresses(false);
    setActiveAddress(address);
    const obj = {
      name: address.zone,
      value: address.zone_id
    };
    zone.current.name = address.zone;
    zone.current.id = address.zone_id;
    if (_manual) {
      setTimeout(() => {
        manual(manualCart, obj, activePaymentMethod, false,coupon.current.value);
      }, 500);
    }
  }

  // HandleCoupon
  function setCoupon(){
    const obj = {
      name:zone.current.name,
      value:zone.current.id
    }
    if(coupon.current.value.length > 1){
      manual(manualCart,obj,activePaymentMethod,false)
    }else{

    }
  }

  function handleCouponChange(){
    if(coupon.current.value.length < 1){
      manualErrors.current = ""
    }
  }

  // getCart
  function getCart() {
    // cart check
    _axios
      .get(buildLink("cart", undefined, window.innerWidth))
      .then((response) => {
        const data = response.data;
        if (!data.success) {
          setEmptyCart(true);
        } else {
          // ---> Facebook PIXEL <---
          ReactPixel.init(pixelID, {}, { debug: true, autoConfig: false });
          ReactPixel.pageView();
          ReactPixel.fbq("track", "PageView");
          if (data) {
            let productArray;
            data.data.products?.map((p, index) => {
              if (index === data.data.products.length - 1) {
                productArray += p.product_id;
              } else {
                productArray = p.product_id + ",";
              }
            });
            ReactPixel.track("InitiateCheckout", {
              content_type: "product",
              content_ids: productArray !== undefined && productArray,
              num_items: data.data.products.length,
              currency: "USD"
            });
          }
          let temp = [];
          const dt = data.data.products;
          for (let index = 0; index < dt.length; index++) {
            let new_product = {};
            let product_option = {};
            new_product.product_id = dt[index]["product_id"];
            new_product.quantity = dt[index]["quantity"];
            if (dt[index]["option"].length !== 0) {
              product_option["type"] = "radio";
              product_option["product_option_id"] =
                dt[index]["option"][0]["product_option_id"];
              product_option["product_option_value_id"] =
                dt[index]["option"][0]["product_option_value_id"];

              new_product.order_option = [product_option];
            }
            temp.push(new_product);
          }
          setManualCart(temp);
          manual(temp, zone, activePaymentMethod, false);
          setCartProducts(data.data.products);
        }
        dispatch({
          type: "setProducts",
          payload:
            response.data?.data?.products?.length > 0
              ? response.data.data.products
              : []
        });
        dispatch({
          type: "setTotals",
          payload:
            response.data?.data?.totals?.length > 0
              ? response.data.data.totals
              : 0
        });
        dispatch({
          type: "setProductsCount",
          payload:
            response.data.total_product_count > 0
              ? response.data.total_product_count
              : 0
        });
      });
    // End cart check
  }

  // Get Zones
  useEffect(() => {
    _axios
      .get(buildLink("zone", undefined, window.innerWidth))
      .then((response) => {
        setZones(response.data.data.zones);
      });

  }, []);

  // Manual Request

  function manual(manualCartProducts, _zone, paymentcode, confirm) {
    setLoading(true);
    window.scroll(0, 0);
    let body = {};

    // if it's first attemp
    if (firstAttemp) {
      body = {
        order_product: manualCartProducts,
        customer_id: customerId,
        firstname: "initial firstname",
        lastname: "initial lastname",
        email: "initialmail@mail.com",
        address_1: "initial address one",
        telephone: "00000000",
        address_2: "",
        city: "",
        shipping_method: "Delivery ( 1-4 days )",
        shipping_code: "ultimate_shipping.ultimate_shipping_0",
        payment_method: "Cash On Delivery",
        payment_code: "cod",
        comment: "",
        country_id: "118",
        zone_id: zone.current.id,
        zone: zone.current.name,
        source_id: 1,
        coupon: "",
        code_version: window.innerWidth > 600 ? "web_desktop" : "web_mobile"
      };
    } else {
      body = {
        order_product: manualCartProducts,
        customer_id: customerId,
        firstname: firstname.current?.value || "",
        lastname: lastname.current?.value || "",
        email: email.current?.value || "",
        address_1: address_1.current?.value || "",
        telephone: "+961" + telephone.current?.value || "",
        address_2: address_2.current?.value || "",
        city: "",
        shipping_method: "Delivery ( 1-4 days )",
        shipping_code: "ultimate_shipping.ultimate_shipping_0",
        payment_method: "Cash On Delivery",
        payment_code: paymentcode,
        comment: comment.current?.value || "",
        country_id: "118",
        zone_id: _zone?.value || zone.current.id,
        zone: _zone?.name || zone.current.name,
        // payment_session:manualResponse.payment_session,
        source_id: 1,
        coupon:coupon.current.value || "" ,
        code_version: window.innerWidth > 600 ? "web_desktop" : "web_mobile"
      };
      const adminId = Cookies.get("user_id");
      if (typeof adminId != "undefined") {
        body["user_id"] = adminId;
      }
    }
    _axios
      .post(buildLink("manual", undefined, window.innerWidth), body)
      .then((response) => {
        setManualResponse(response.data);
        if (typeof response.data.success == "undefined") {
          manualErrors.current = response.data.error;
          paymentForm(confirm, paymentcode);
          setLoading(false);
          setConfirmDisalbe(false);
        } else {
          manualErrors.current = "";
          paymentForm(confirm, paymentcode);
          setLoading(false);
          if (firstAttemp) setFirstAttemp(false);
        }
      });
  }

  // Update quantity
  function updateQuantity(key, quantity) {
    setLoading(true);
    const obj = { key, quantity };
    _axios
      .put(buildLink("cart", undefined, window.innerWidth), obj)
      .then(() => {
        getCart();
      });
  }

  // Remove ITem
  function RemoveItem(key) {
    setLoading(true);

    _axios
      .delete(buildLink("cart", undefined, window.innerWidth), { key })
      .then(() => {
        getCart();
      });
  }

  //submit form
  function submitForm(e) {
    e.preventDefault();
    
      setConfirmDisalbe(true);
      manual(manualCart, zone, activePaymentMethod, true);
  }
  // Zone changed

  function zoneChanged(e, z) {
    const sel = e.target;

    const obj = {
      name: sel.options[sel.selectedIndex].text,
      value: sel.value
    };
    zone.current.id = sel.value;
    zone.current.name = sel.options[sel.selectedIndex].text;
    manual(manualCart, obj, activePaymentMethod, false);
  }
  // Payment form
  function paymentForm(confirm, p_m) {
    setLoading(true);
    _axios
      .post(buildLink("payment_form" , undefined, window.innerWidth), { payment_method: p_m })
      .then((response) => {
        const data = response.data;
        try {
          document.getElementById("simp-id").outerHTML = "";
        } catch (e) {}
        // const script = document.createElement("script");
        // script.src = "https://www.simplify.com/commerce/simplify.pay.js";
        // script.async = false;
        // script.id = "simp-id";
        // document.body.appendChild(script);
        setSiResponse(data);
        // const script = document.createElement("script");
        // script.src = "https://test-bobsal.gateway.mastercard.com/checkout/version/61/checkout.js";
        // script.type="application/javascript"
        // script.async = true;
        // script.id = "mpgs-id";
        // script.data-error="data-error=“errorCallback” data-cancel=“cancelCallback” "
        // document.body.appendChild(script);
        // setSiResponse(data);

        // Developer hint [here you must call the function to complete payment (incase backend payment changed)]
        if (data.success) {
          
          setSiResponse(data);
          
          if(activePaymentMethod === 'mpgs' && confirm){
            _axios.get('https://www.ishtari-mobile.com/v2/index.php/?route=payment/mpgs/getSessionId')
            .then((res) => {
          
          // console.log(res.data.payment_session)
            
            window.paymentStart(res.data.payment_session)
          
          })
        }
          if (activePaymentMethod === "cod" && confirm) {
            if (Object.keys(manualErrors.current).length === 0) {
              confirmOrder(data.confirm_url, data.success_url);
            }
          }
        }
      });
  }

  function confirmOrder(c_url, s_url) {
    _axios.post(c_url).then((response) => {
      const data = response.data;
      if (data.success) {
        successOrder(s_url);
      }
    });
  }
// +96103005854
  function successOrder(url) {
    _axios.get(url).then((response) => {
      const data = response.data;
      if (data.success) {
        history(
           "/success/",
         {state: { data: response.data }}
        );
      }
    });
  }
  // View


  

  const phoneHanlder = (childData, isValid) => {
    
    if (isValid === true) {
      telephone.current.value = childData;
      setErr("");
    }else if(isValid === false){
      telephone.current.value = childData;
    }

    setIsValid(isValid);
  };
  const AdminPhoneHandler = (obj, isValid) => {
    if (isValid) {
      firstname.current.value = obj.firstname;
      lastname.current.value = obj.lastname;
      // email.current.value  = obj.email
      address_1.current.value = obj.address;
      telephone.current.value = obj.telephone;
      address_2.current.value = obj.address2;
      zone.current.name = obj.city;
      zone.current.id = obj.zone;
      const data = {
        name: obj.city,
        value: obj.zone
      };
      manual(manualCart, data, activePaymentMethod, false);
    }
    const onEscape = function (action) {
      window &&
        window.addEventListener("keydown", (e) => {
          if (e.key === "Escape") {
            action();
          }
        });
    };
    onEscape(() => {
      telephone.current.blur();
    });
  };
  const [activePaymentMethod, setActivePaymentMethod] = useState();
  const [siResponse, setSiResponse] = useState({
    secret_key: "l0",
    button_name: "Pay with this card",
    amount: 0,
    confirm_url: "0",
    success_url: "0",
    order_id: 0
  });

  function setPaymentMethod(pm) {
    setActivePaymentMethod(pm);
    manual(cartProducts, zone, pm, false);
  }




  //
  //
  // View
  return (
    <div className="container px-4">
      <Helmet>
      <base href="/" />
      {/* <script type="application/javascript" src="https://test-bobsal.gateway.mastercard.com/checkout/version/61/checkout.js" /> */}
      </Helmet>
      {!emptyCart && (
        <h2 className="py-6 text-3xl font-semibold flex items-center">
          <span> Checkout </span>
        </h2>
      )}
      {/* If cart is empty */}
      {emptyCart && (
        <div className="flex items-center justify-center flex-col h-screen text-dblack">
          <i className="icon icon-basket text-5xl" />
          <h2 className=" text-2xl my-3 font-semibold">
            Your shopping basket is empty
          </h2>
          <h3 className="my-1 font-light text-xl">
            What are you waiting for !
          </h3>
          <Link
            className="bg-dblue px-20 py-3 text-white mt-4 inline-block font-semibold rounded hover:bg-dbluedark"
            to={`/`}
          >
            START SHOPPING
          </Link>
        </div>
      )}
      {/* End If cart is empty */}
      {/* If not cart empty , address empty , loading cart or account */}
      {!emptyCart && !emptyAddress && (
        <form autoComplete="off" onSubmit={(e) => submitForm(e)}>
          <div className="flex-row md:flex">
            <div className="w-full md:w-8/12 mr-4">
              {" "}
              {/* If user is not logged in */}{" "}
              {!loged && (
                <div className="cart">
                  <div className="cart-header">
                    <span className="cart-icon">
                      <i className="icon icon-location" />
                    </span>{" "}
                    <span> Address </span>{" "}
                  </div>{" "}
                  <div className="cart-body relative">
                    {" "}
                    {}{" "}
                    <div className="flex flex-wrap">
                      <div className="w-full xl:w-1/2 lg:w-1/2 pr-0  xl:pr-4 lg:pr-4">
                        {" "}
                        {/* Firstname */}{" "}
                        <div className="input mb-6 required">
                          <label htmlFor="firstname"> First name </label>{" "}
                          <input
                            type="text"
                            id="firstname"
                            ref={firstname}
                            minLength={3}
                            required
                            onChange={(e) => setFirstnameState(e.target.value)}
                            a={true}
                          />
                        </div>{" "}
                        <span className="text-dbase text-xs ml-2 relative -top-3">
                          {" "}
                          {firstnameState.length < 3 && manualErrors.current?.address?.firstname}{" "}
                        </span>{" "}
                        {/* Lastname */}{" "}
                        <div className="input mb-6 required">
                          <label htmlFor="lastname"> Last name </label>{" "}
                          <input
                            type="text"
                            id="lastname"
                            ref={lastname}
                            onChange={(e) => setLastnameState(e.target.value)}
                            minLength={3}
                            required
                          />
                        </div>{" "}
                        <span className="text-dbase text-xs ml-2 relative -top-3">
                          {" "}
                          {lastnameState.length < 3 && manualErrors.current?.address?.lastname}{" "}
                        </span>{" "}
                        {/* Phone */}{" "}
                        <div className="flex items-center -space-x-3">
                          <div className="flex items-center space-x-1 border-b  -mb-1.5 border-dinputBorder">
                            <img
                              className="w-6"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAMrSURBVFhH7VhbSFRRFD1iVBj1I/QVhJMoaQ1mKSaBmJmWaWpgD7KHZlmJWoL0UMtCRSU0ykcPx2cPMsvSKZ/5IiM1K0clMUMkKzXTNAMtXe17vR8JfkhzLgwxCxb3nn3Onrlr1tlnZg8bXGaK/4F6IbpGvRBdo17IXPl1lpgcZP1sPuRiH/GHwRLxOts8T7JfmjbIRXT0QF2uAj4MzDrPkwwy4uXwe7DUVfCqj5ci8kFWIYY528ByiZmbwJLNoSwMwMjET2mWL2QRMjk1BdunYWCqjVA88INrRQQsio6BXVXiaGOatIovZBHS9KUFxnkeONGswkUiS7eDZdFxuFVE4krbfTR/65JW8gN3IUH1SWCXlmN3XSLYtfWwLzuNwIZ0mJAzZgUHYPkoUFrJF1yFJL5SIfxNLlqGuuFUEg6W7QqWRfWR4QCb4mB4VZ4TxbF0W5R8ei1l8QF3R+r7NGBJK8BytkBRsB+OZafofitYnjuJcsbJpgw4lJ9BDG2xofFRKUt7cBXC0qxhku+LSKEuVE5Q0CnFVI642Vk6fXrd8hTdURYHIaQ6BqGN6VKm9uAmRHHXBwMTY/CoisbBugR6YEdywWPaiUxnErEd80jIXmEuZQ2sCg9LmXzA1REHdSi5slZ0I6TxOhbd9sTOqgswEJwgR9wrorBBHYy+8RGsfkzHcYoSLcM9UrZ24CKkc/QzWLQRdtXGI7e7hsTYwO5JKFY+9INFoT8VvVAj5E6qNdXJZqohM4SRUAFdlMsDWgsRCtafjtwpuk/W3AO7bE41kgWXykh6aBdxSwkilubvEdf718TB+9l5eFdGid/2pR8bxLi24OJI2/de+FABx7bmS5FpsBQrsDs7aFu5waPirBSdCWXBPtT1t0ujfwfXGvkbe2rjxE+cpa0THTGkopcTsgjxfR4Ls6IAaURvcsMOZuojsK+OkCL8wa0fmdS0o6/pBRLUSdSH0G+pzl78phhaO6gf6cWQ5i1Vdg8OZQcD77oxMctraENuHaLQBQ4xI4yxxRhgC2bMCeNBtlC8Thoay9Ixcu3Z59Kfy9XD6/9F0TXqhega9UJ0i6b4A/QB3t+zIyKhAAAAAElFTkSuQmCC"
                              alt="Lebanon"
                            />
                            <p className="w-14 select-none"> {"961"} </p>{" "}
                          </div>
                          <div className="input mb-6 required ">
                            <label htmlFor="telephone"> Telephone </label>{" "}
                            <HandlePhoneModel
                              phone={telephone}
                              phoneHanlder={phoneHanlder}
                              setConfirmDisalbe={setConfirmDisalbe}
                              AdminPhoneHandler={AdminPhoneHandler}
                              setPhoneState={setPhoneState}
                              phoneNumber= {phoneNumber}
                              setPhoneNumber={setPhoneNumber}
                            />{" "}
                            <p className="text-dbase text-xs ml-2"> {err} </p>{" "}
                          </div>{" "}
                        </div>{" "}
                        <span className="text-dbase text-xs ml-2">
                          {" "}
                          { phoneState.length < 7 &&  manualErrors.current?.address?.telephone}{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="w-full xl:w-1/2 lg:w-1/2 pl-0  xl:pl-4 lg:pl-4">
                        {" "}
                        {/* Zone */}{" "}
                        <div className="input mb-6 relative required">
                          <label htmlFor="zone_id"> Zone </label>
                          <div className=" ">
                            {loading && (
                              <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                                <Loader styles="h-9 w-9 text-dblue ml-4" />
                              </div>
                            )}
                            <select
                              id="zone_id"
                              disabled={loading}
                              required
                              onChange={(e) => zoneChanged(e)}
                            >
                              {zones.length > 0 &&
                                zones.map((z) =>
                                  z.zone_id === zone.current.id ? (
                                    <option
                                      key={z.zone_id}
                                      selected
                                      value={z.zone_id}
                                    >
                                      {" "}
                                      {z.name}{" "}
                                    </option>
                                  ) : (
                                    <option key={z.zone_id} value={z.zone_id}>
                                      {" "}
                                      {z.name}{" "}
                                    </option>
                                  )
                                )}{" "}
                            </select>{" "}
                          </div>
                        </div>
                        {/* Address */}{" "}
                        <div className="input mb-6 required">
                          <label htmlFor="address_1"> Address </label>{" "}
                          <input
                            type="text"
                            id="address_1"
                            ref={address_1}
                            minLength={3}
                            onChange={(e) => setAddressState(e.target.value)}
                            required
                          />
                        </div>{" "}
                        <span className="text-dbase text-xs ml-2 relative -top-3">
                          {" "}
                          {addressState.length < 8 &&  manualErrors.current?.address?.address_1}{" "}
                        </span>{" "}
                        {/* More details */}{" "}
                        <div className="input mb-6">
                          <label htmlFor="address_2">
                            More Address Details{" "}
                          </label>{" "}
                          <input
                            type="text"
                            id="address_2"
                            ref={address_2}
                            min={2}
                          />{" "}
                        </div>{" "}
                        {/* Comment */}{" "}
                        <div className="input mb-6">
                          <label htmlFor="comment"> Comment </label>{" "}
                          <input type="text" id="comment" ref={comment} />{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              )}{" "}
              {/* End If user is not logged in */} {/* If user is logged in */}{" "}
              {loged && (
                <div className="cart ">
                  <div className="cart-header flex justify-between">
                    <div className={`flex items-center`}>
                      <span className="cart-icon">
                        <i className="icon icon-location" />
                      </span>{" "}
                      <span> Address </span>{" "}
                    </div>
                    <div
                      className={`text-dgreen cursor-pointer`}
                      onClick={() => setShowAddresses(!showAddresses)}
                    >
                      {!showAddresses ? (
                        <span> Show Addresses </span>
                      ) : (
                        <span> Hide Addresses </span>
                      )}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="cart-body relative">
                    <div className=" hidden">
                      <input
                        type="text"
                        value={activeAddress.firstname}
                        ref={firstname}
                      />{" "}
                      <input
                        type="text"
                        value={activeAddress.lastname}
                        ref={lastname}
                      />{" "}
                      <input
                        type="text"
                        value={activeAddress.address_1}
                        ref={address_1}
                      />{" "}
                      <input
                        type="text"
                        value={activeAddress.address_2}
                        ref={address_2}
                      />{" "}
                      <input
                        type="text"
                        value={activeAddress.telephone}
                        ref={telephone}
                      />{" "}
                      <input
                        type="text"
                        value={activeAddress.zone_id}
                        ref={zone.zone_id}
                      />{" "}
                    </div>
                    <div>
                      {" "}
                      {Object.keys(manualErrors.current).map((errorKey) => (
                        <div>
                          {" "}
                          {errorKey === "address" && (
                            <div className="border  border-dinputBorder py-4 px-2 mb-4">
                              <h2 className="text-lg font-semibold mb-2">
                                Address Errors{" "}
                              </h2>{" "}
                              {Object.keys(manualErrors.current[errorKey]).map(
                                (addressErrorKey) => (
                                  <div className="text-dbase">
                                    <span className="capitalize">
                                      {" "}
                                      {addressErrorKey.replace("_", " ")}:
                                    </span>{" "}
                                    <span className="ml-2">
                                      {" "}
                                      {
                                        manualErrors.current[errorKey][
                                          addressErrorKey
                                        ]
                                      }{" "}
                                    </span>{" "}
                                  </div>
                                )
                              )}{" "}
                            </div>
                          )}{" "}
                        </div>
                      ))}{" "}
                    </div>{" "}
                    {loading && (
                      <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                        <Loader styles="h-9 w-9 text-dblue ml-4" />
                      </div>
                    )}{" "}
                    <p>
                      <span> First name: </span>{" "}
                      <span className={`font-semibold`}>
                        {" "}
                        {activeAddress.firstname}{" "}
                      </span>{" "}
                    </p>{" "}
                    <p>
                      <span> Last name: </span>{" "}
                      <span className={`font-semibold`}>
                        {" "}
                        {activeAddress.lastname}{" "}
                      </span>{" "}
                    </p>{" "}
                    <p>
                      <span> Zone: </span>{" "}
                      <span className={`font-semibold`}>
                        {" "}
                        {activeAddress.zone}{" "}
                      </span>{" "}
                    </p>{" "}
                    <p>
                      <span> Address: </span>{" "}
                      <span className={`font-semibold`}>
                        {" "}
                        {activeAddress.address_1 +
                          " " +
                          activeAddress.address_2}{" "}
                      </span>{" "}
                    </p>{" "}
                    <p>
                      <span> Telephone: </span>{" "}
                      <span className={`font-semibold`}>
                        {" "}
                        {activeAddress.telephone}{" "}
                      </span>{" "}
                    </p>{" "}
                    <Link
                      to={{
                        pathname:
                          "/account/address/" +
                          activeAddress.address_id +
                          "/edit",
                        search: "from-checkout=true"
                      }}
                      className="text-white bg-dblue px-2 py-1 mt-1 inline-block"
                    >
                      <span> Edit address </span>{" "}
                      <i className="icon icon-edit ml-1"> </i>{" "}
                    </Link>{" "}
                    {/*Addresses*/}{" "}
                    {/* +96103006964 */}
                    {showAddresses && (
                      <div
                        className={` py-2 flex-row md:flex w-full overflow-scroll`}
                      >
                        <Link
                          to={`${path}/account/address/add`}
                          className={`text-sm bg-dgreen text-white border border-dinputBorder p-2 flex items-center justify-center flex-col`}
                          style={{ minWidth: "19%" }}
                        >
                          <span className={`font-semibold`}>
                            Add New Address{" "}
                          </span>{" "}
                          <span className={`text-3xl`}> + </span>{" "}
                        </Link>{" "}
                        {addresses.map((address) => (
                          <div
                            key={address.address_id}
                            onClick={() => changeAddress(address, true)}
                            className={`text-sm bg-white border border-dinputBorder p-2 hover:bg-dgrey1 hover:bg-opacity-20 cursor-pointer`}
                            style={{ minWidth: "19%" }}
                          >
                            <p>
                              <span> First name: </span>{" "}
                              <span className={`font-semibold`}>
                                {" "}
                                {address.firstname}{" "}
                              </span>{" "}
                            </p>{" "}
                            <p>
                              <span> Last name: </span>{" "}
                              <span className={`font-semibold`}>
                                {" "}
                                {address.lastname}{" "}
                              </span>{" "}
                            </p>{" "}
                            <p>
                              <span> Zone: </span>{" "}
                              <span className={`font-semibold`}>
                                {" "}
                                {address.zone}{" "}
                              </span>{" "}
                            </p>{" "}
                            <p>
                              <span> Address: </span>{" "}
                              <span className={`font-semibold`}>
                                {" "}
                                {address.address_1 +
                                  " " +
                                  address.address_2}{" "}
                              </span>{" "}
                            </p>{" "}
                            <p>
                              <span> Telephone: </span>{" "}
                              <span className={`font-semibold`}>
                                {" "}
                                {address.telephone}{" "}
                              </span>{" "}
                            </p>{" "}
                          </div>
                        ))}{" "}
                      </div>
                    )}{" "}
                  </div>{" "}
                </div>
              )}{" "}
              {/* End If user is logged in */}
              {/* Shipping method */}{" "}
              <div className="cart">
                <div className="cart-header">
                  <span className="cart-icon">
                    <i className="icon icon-bus" />
                  </span>{" "}
                  <span> Shipping Method </span>{" "}
                </div>{" "}
                <div className="cart-body relative">
                  {" "}
                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                      <Loader styles="h-9 w-9 text-dblue ml-4" />
                    </div>
                  )}{" "}
                  {manualResponse.shipping_method && (
                    <div>
                      <input
                        type="radio"
                        checked
                        className="mr-2"
                        onChange={() => {}}
                      />{" "}
                      <label>
                        {" "}
                        {manualResponse?.order_total?.map(
                          (order) =>
                            order.code === "shipping" &&
                            order.title + " " + order.text
                        )}{" "}
                      </label>{" "}
                    </div>
                  )}{" "}
                </div>{" "}
              </div>{" "}
              {/* End shipping method */} {/* Payment Method */}{" "}
              <div className="cart">
                <div className="cart-header">
                  <span className="cart-icon">
                    <i className="icon icon-dollar" />
                  </span>{" "}
                  <span> Payment Method </span>{" "}
                </div>{" "}
                <div className="cart-body relative">
                  {" "}
                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                      <Loader styles="h-9 w-9 text-dblue ml-4" />
                    </div>
                  )}{" "}
                  {manualResponse.payment_method &&
                    Object.keys(manualResponse["payment_method"]).map((key,index) => (
                      <div
                        key={manualResponse.payment_method[key]["code"]}
                        className="mb-1"
                      >
                        <input
                          id={manualResponse.payment_method[key]["code"]}
                          type="radio"
                          name="payment_method"
                          className="mr-2"
                          onChange={() =>
                            setPaymentMethod(
                              manualResponse.payment_method[key]["code"]
                            )
                          }
                          checked={
                            manualResponse.payment_method[key]["code"] ===
                            activePaymentMethod
                          }
                        />
                        <label for={manualResponse.payment_method[key]["code"]}>
                          {" "}
                          {manualResponse.payment_method[key]["title"]}{" "}
                        </label>{" "}
                      </div>
                    ))}{" "}
                </div>{" "}
              </div>{" "}
              {/*End  Payment Method */} {/* My cart */}{" "}
              <div className="cart">
                <div className="cart-header justify-between">
                  <span className="flex items-center">
                    <span className="cart-icon">
                      <i className="icon icon-basket" />
                    </span>{" "}
                    <span> Basket </span>{" "}
                  </span>{" "}
                  {/* Cart error */}{" "}
                  <span className="text-dbase">
                    {" "}
                    {manualErrors.current?.product?.stock}{" "}
                  </span>{" "}
                </div>

                <div className="cart-body relative">
                  {" "}
                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                      <Loader styles="h-9 w-9 text-dblue ml-4" />
                    </div>
                  )}{" "}
                  {manualResponse.order_product?.length > 0 &&
                    manualResponse.order_product.map((product) => (
                      // Desktop Design
                      <div>
                        <Link
                        // to={`${product.name.replace(/\s+&amp;\s+|\s+&gt;\s+/g,"-").replace(/\s+/g,'-').replace('/','-')}/p=${product.product_id}`}
                          key={product.product_id}
                          className={`hidden xl:flex lg:flex mb-2  py-2 rounded ${
                            product.stock
                              ? "bg-white "
                              : "bg-dbase bg-opacity-10"
                          }`}
                        >
                          <img
                          onClick={() => history(`${product.name.replace(/\s+&amp;\s+|\s+&gt;\s+/g,"-").replace(/\s+/g,'-').replace('/','-')}/p=${product.product_id}`)}
                            src={product.image}
                            className="w-24 block rounded ml-2"
                            alt={product.name}
                          />{" "}
                          <div className="flex flex-col justify-between items-start px-9 text-dblack py-2 flex-grow ">
                            <p className="text-d13 text-dgrey1">
                              {" "}
                              {product.sku}{" "}
                            </p>{" "}
                            <p
                              className=" text-sm font-semibold"
                              dangerouslySetInnerHTML={{ __html: product.name }}
                            />{" "}
                            {product.option.length > 0 && (
                              <p className="text-dgreen text-sm">
                                {" "}
                                {product.option[0].name +
                                  " (" +
                                  product.option[0].value +
                                  ")"}{" "}
                              </p>
                            )}{" "}
                            <p
                              className="cursor-pointer text-dgrey1 text-xs text-center"
                              onClick={() => updateQuantity(product.key, 0)}
                            >
                              <span> Remove </span>{" "}
                              <i className="icon icon-trash ml-1" />
                            </p>{" "}
                          </div>
                          <div className="py-2 px-6 w-48 flex flex-col items-end text-dblack justify-center">
                            <span className=" font-semibold text-lg">
                              {" "}
                              {product.price_formatted}{" "}
                            </span>{" "}
                            <div className="flex mt-4">
                              <p
                                onClick={() =>
                                  updateQuantity(
                                    product.key,
                                    Number(product.quantity) - 1
                                  )
                                }
                                className="text-center w-10 h-10  text-2xl border border-dinputBorder rounded-tl rounded-bl cursor-pointer hover:shadow"
                              >
                                -
                              </p>{" "}
                              <input
                                type="number"
                                className="border border-dinputBorder w-20 h-10 border-r-0 border-l-0 text-center"
                                value={product.quantity}
                                onChange={() => {}}
                              />{" "}
                              <p
                                onClick={() =>
                                  updateQuantity(
                                    product.key,
                                    Number(product.quantity) + 1
                                  )
                                }
                                className="text-center w-10 h-10  text-2xl border border-dinputBorder  rounded-tr rounded-br cursor-pointer hover:shadow"
                              >
                                +
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </Link>{" "}
                        {/* Mobile design */}{" "}
                        <Link 
                        // to={`${product.name.replace(/\s+&amp;\s+|\s+&gt;\s+/g,"-").replace(/\s+/g,'-').replace('/','-')}/p=${product.product_id}`}
                          className={`flex xl:hidden lg:hidden mb-2 -mx-2 py-2 rounded ${
                            product.stock
                              ? "bg-white "
                              : "bg-dbase bg-opacity-10"
                          }`}
                        >
                          <div className="w-3/12">
                            <img
                            onClick={() => history(`${product.name.replace(/\s+&amp;\s+|\s+&gt;\s+/g,"-").replace(/\s+/g,'-').replace('/','-')}/p=${product.product_id}`)}
                              src={product.image}
                              className="w-full block rounded"
                              alt={product.name}
                            />{" "}
                            <div className="flex flex-col items-end text-dblack justify-center">
                              <div className="flex mt-2">
                                <button
                                  onClick={() =>
                                    updateQuantity(
                                      product.key,
                                      Number(product.quantity) - 1
                                    )
                                  }
                                  className="w-1/3 h-10 text-2xl border border-dinputBorder rounded-tl rounded-bl cursor-pointer hover:shadow"
                                >
                                  -
                                </button>{" "}
                                <input
                                  type="number"
                                  className="border border-dinputBorder w-1/3 h-10 border-r-0 border-l-0 text-center"
                                  value={product.quantity}
                                />{" "}
                                <button
                                  onClick={() =>
                                    updateQuantity(
                                      product.key,
                                      Number(product.quantity) + 1
                                    )
                                  }
                                  className="w-1/3 h-10 text-2xl border border-dinputBorder  rounded-tr rounded-br cursor-pointer hover:shadow"
                                >
                                  +
                                </button>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="w-9/12 flex flex-col justify-between items-start pl-6 text-dblack py-2 flex-grow ">
                            <p className="text-d13 text-dgrey1">
                              {" "}
                              {product.sku}{" "}
                            </p>{" "}
                            <p
                              className=" text-sm "
                              dangerouslySetInnerHTML={{ __html: product.name }}
                            ></p>{" "}
                            {product.option.length > 0 && (
                              <p className="text-dgreen text-sm">
                                {" "}
                                {product.option[0].name +
                                  " (" +
                                  product.option[0].value +
                                  ")"}{" "}
                              </p>
                            )}{" "}
                            <span className=" font-semibold text-lg">
                              {" "}
                              {product.price_formatted}{" "}
                            </span>
                            <button
                              className="cursor-pointer text-dgrey1 text-xs"
                              onClick={() => updateQuantity(product.key, 0)}
                            >
                              <span> Remove </span>{" "}
                              <i className="icon icon-trash ml-1"> </i>{" "}
                            </button>{" "}
                          </div>{" "}
                      </Link>
                        </div>
                    ))}
                </div>
              </div>{" "}
              {/* End My cart */}{" "}
            </div>
            <div
              className={`relative w-full pb-3 md:pb-0 md:w-4/12 ml-0 md:ml-4 mt-4 `}
            >
              <div className={`${(state.admin && width > 650) && "fixed  w-full md:w-3/12"} `}>
                <div
                  className={` border border-dinputBorder p-6 rounded  sticky top-7 bg-white `}
                >
                  
                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full bg-dblack  bg-opacity-10 flex items-center justify-center">
                      <Loader styles="h-9 w-9 text-dblue ml-4" />
                    </div>
                  )}
                  <h1 className=" font-semibold text-xl text-dblack mb-4">
                    Order Summary
                  </h1>
                  <p className={(coupon?.current?.value?.length > 1 && manualErrors?.current?.temp_coupon) ?  "text-xs text-dbase" : 'hidden'}>{(coupon.current?.value?.length > 1 && manualErrors.current?.temp_coupon) ? manualErrors?.current?.temp_coupon : '' }</p>
                  <div className="flex">
                    <input
                      type="text"
                      className="border border-dinputBorder flex-grow rounded-tl rounded-bl border-r-0 h-10 px-5"
                      placeholder="Coupon Code or Gift Card"
                      ref={coupon}
                      onChange={() => handleCouponChange()}
                    />
                    <div onClick={() => setCoupon()} className="bg-dblue text-white px-3 h-10 rounded-tr rounded-br text-sm">

                      <p className="text-center mt-3">APPLY</p>
                    </div>{" "}
                  </div>{" "}
                  <div className="flex justify-between items-center text-dgrey1 font-light mt-1">
                    <p>
                      {" "}
                      Subtotal({cartProducts.length}
                      items){" "}
                    </p>{" "}
                  </div>
                  <h2 className="mt-4 mb-2 font-semibold text-lg">
                    Order Totals
                  </h2>
                  <div>
                    {manualResponse.order_total?.map((total) => (
                      <div
                        className={`flex items-center justify-between mb-1 ${total.code === 'ultimate_coupons' ? 'text-dgreen' : 'text-dblack'}`}
                        key={total.title}
                      >
                        <span> {total.title} </span> <span> {total.text} </span>{" "}
                      </div>
                    ))}{" "}
                  </div>
                  <button
                    disabled={confirmDisable}
                    className={`block text-center h-12  ${
                      confirmDisable
                        ? `bg-dgrey1 cursor-not-allowed hover:bg-dgrey1`
                        : `bg-dblue hover:bg-dbluedark`
                    } text-white rounded w-full mt-4 `}
                  >
                    {confirmDisable && loading ? (
                      <div className="lds-ellipsis">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    ) : (
                      `CONFIRM ORDER`
                    )}
                  </button>
                  {
                    <>
                    {/* <button
                      data-sc-key={siResponse?.secret_key}
                      id="pay"
                      style={{ display: "none" }}
                      data-name="Ishtari Lebanon"
                      data-operation="create.payment"
                      data-description={siResponse?.order_id}
                      data-reference={siResponse?.order_id}
                      data-amount={siResponse?.amount}
                      data-color="#1733ff"
                    >
                      Confirm with payment
                    </button> */}
                    {/* <button id="pay23"  onClick={window.Checkout.showPaymentPage} >Pay with Payment Page"</button> */}
                    </>
                  }{" "}
                </div>
              </div>
            </div>
          </div>{" "}
        </form>
      )}{" "}
      <code>
        {" "}
        {
          // JSON.stringify(manualResponse)
        }{" "}
      </code>{" "}
      {/* End If not cart empty , address empty , loading cart or account */}{" "}
    </div>
  );
}

export default CheckoutV3;
