import { Link } from "react-router-dom";
import { path } from "../urls";
import {
  BsBasket,
  BsEmojiHeartEyes,
  BsFillArrowUpSquareFill,
  BsFillCartCheckFill,
  BsHeart,
  BsHourglass,
  BsShop,
  BsStar,
} from "react-icons/bs";
import { MdAvTimer } from "react-icons/md";
import {
  RiCheckLine,
  RiCoupon3Fill,
  RiCouponLine,
  RiGitRepositoryCommitsFill,
  RiProfileLine,
} from "react-icons/ri";
import {
  FaCheckCircle,
  FaRegAddressCard,
  FaRegIdBadge,
  FaTv,
} from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import {
  CiBookmarkCheck,
  CiCreditCard1,
  CiUser,
  CiWallet,
} from "react-icons/ci";
import { MdHourglassFull } from "react-icons/md";
import { BsStarFill } from "react-icons/bs";
import _axios from "../axios";
import buildLink from "../urls";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { AccountContext } from "../contexts/AccountContext";
import { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { IoIosClose, IoIosLogOut, IoIosTimer } from "react-icons/io";
import { FcFeedback } from "react-icons/fc";
import { IoBulbOutline } from "react-icons/io5";
import { ImProfile } from "react-icons/im";
import { AiOutlineProfile } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";

function MyAccount(props) {
  const [state, dispatch] = useContext(AccountContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const statuses = ["complete", "processing", "trash"];
  const dataInformation = useContext(CurrencyContext);
  const router = useNavigate();
  const handleTabClick = (selectedStatus) => {
    router.push(`/account/orders?status=${selectedStatus}`, undefined, {
      shallow: true,
    });
  };

  async function logout(type) {
    try {
      dispatch({ type: "setLoading", payload: true });
      await _axios.post(buildLink("logout"));
      dispatch({ type: "setSeller", payload: false });
      Cookies.remove("seller_id");
      router("/");
      window.location.reload();
    } catch (error) {
    } finally {
      dispatch({ type: "setLoading", payload: false });
    }
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="flex flex-col p-4 md:hidden space-y-2">
      {/* Account Management Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className=" text-sm border-b pb-2 border-b-dgreyQtyProduct">
          Account Management
        </h2>
        {/* <div className="flex justify-between items-start"> */}
        <div className="grid grid-cols-5 gap-24 ml-12">
          <Link to="/account/profile">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <CiUser className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">Profile</span>
            </div>
          </Link>
          <Link to="/account/addresses">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <FaRegAddressCard className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">
                Addresses
              </span>
            </div>
          </Link>
          <Link to="/" onClick={() => logout()}>
            <div className="flex flex-col items-center justify-center h-24 rounded-lg">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <IoIosLogOut className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">Logout</span>
            </div>
          </Link>
        </div>
      </div>
      {/* </div> */}

      {/* Status Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-sm border-b border-b-dgreyQtyProduct pb-2">
          Orders
        </h2>
        <div className="grid grid-cols-5 gap-24 ml-12">
          {" "}
          {/* Added gap-4 for spacing */}
          <Link to="/account/orders?status=processing">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Processing */}
                <BsHourglass className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">
                Processing
              </span>
            </div>
          </Link>
          <Link to="/account/orders?status=complete">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Completed */}
                <CiBookmarkCheck className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">
                Completed
              </span>
            </div>
          </Link>
          <Link to="/account/orders?status=trash">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                {/* Icon for Cancelled */}
                <IoIosClose className="text-6xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">
                Cancelled
              </span>
            </div>
          </Link>
        </div>
      </div>

      {/* Services Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-sm border-b border-b-dgreyQtyProduct pb-2">
          Services
        </h2>
        <div className="relative">
          {/* Grid container with horizontal scroll */}
          <div className="grid grid-flow-col gap-4 overflow-x-auto scrollbar-hide mx-1">
            <Link to="/account/memberShip">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <FaRegIdBadge className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">
                  Membership
                </span>
              </div>
            </Link>
            <Link to="/account/wallet">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <CiWallet className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">Wallet</span>
              </div>
            </Link>
            {state.allow_pay_by_saved_card && (
              <Link to="/account/paymentcards">
                <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                  <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                    <CiCreditCard1 className="text-2xl text-black" />
                  </div>
                  <span className="font-semibold text-black text-sm">
                    Cards
                  </span>
                </div>
              </Link>
            )}
            <Link to="/account/coupons">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <RiCouponLine className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">
                  Coupons
                </span>
              </div>
            </Link>
            <Link to="/account/checkin">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <RiCheckLine className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">
                  Checkin
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Wishlist , Review and Recently Viewed Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-sm border-b pb-2 border-b-dgreyQtyProduct">
          Product And Review
        </h2>
        <div className="relative">
          {/* Grid container with horizontal scroll */}
          <div className="grid grid-flow-col gap-4 overflow-x-auto scrollbar-hide mx-4">
            <Link to="/account/wishlist">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BsHeart className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">
                  Wishlist
                </span>
              </div>
            </Link>
            <Link to="/account/recentlyViewed">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <IoIosTimer className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm whitespace-nowrap">
                  Recently Viewed
                </span>
              </div>
            </Link>
            <Link to="/account/reviewCenter">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BsStar className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm whitespace-nowrap">
                  Reviews
                </span>
              </div>
            </Link>
            <Link to="/account/buyagain">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BsBasket className="text-2xl text-black" />
                </div>
                <span className="whitespace-nowrap font-semibold text-black text-sm">
                  Buy Again
                </span>
              </div>
            </Link>
            <Link to="/account/shops">
              <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-gray-100 p-2">
                <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                  <BsShop className="text-2xl text-black" />
                </div>
                <span className="font-semibold text-black text-sm">Shops</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Feedback and Suggestion Section */}
      <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
        <h2 className="text-sm border-b pb-2 border-b-dgreyQtyProduct">
          Feedback And Suggestions
        </h2>
        <div className="grid grid-cols-5 gap-24 ml-8">
          <Link to="/account/feedback">
            <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-2">
              <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                <VscFeedback className="text-2xl text-black" />
              </div>
              <span className="font-semibold text-black text-sm">Feedback</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
