import _axios from "../axios";
import UserSidebar from "../components/UserSidebar";
import PointsLoader from "../components/PointsLoader";
import useDeviceSize from "../components/useDeviceSize";
import { AccountContext } from "../contexts/AccountContext";
import buildLink from "../urls";
import { useContext, useEffect, useRef, useState } from "react";
import { FaEdit, FaServicestack, FaWallet } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward, IoIosSend } from "react-icons/io";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { CurrencyContext } from "../contexts/CurrencyContext";
import Select from "react-select";
import { BsCurrencyExchange } from "react-icons/bs";
import { FcServices } from "react-icons/fc";
import { TbHexagonNumber1, TbMenu, TbMenu2 } from "react-icons/tb";
import Popup from "reactjs-popup";
import { BiTransferAlt } from "react-icons/bi";
import Loader from "../components/Loader";
import Flag from "react-world-flags";

function Wallet() {
  const [width] = useDeviceSize();
  const [state] = useContext(AccountContext);
  const [balance, setBalance] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const limit = 10;
  const router = useNavigate();
  const firstname = state?.firstname ? state?.firstname : "";
  const lastname = state?.lastname ? state?.lastname : "";
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [rememberEmail, setRememberEmail] = useState(false);
  const [savedEmails, setSavedEmails] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("GHS");
  const [sentselectedCurrency, setSentSelectedCurrency] = useState("");
  const { Option } = Select;
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const dataInformation = useContext(CurrencyContext);
  const [activetab, setactivetab] = useState(1);
  const [Loadingsubmit, setLoadingsubmit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currencyToCountry = {
    GHS: "GH", // Ghanas
  };
  const handleOptionClick = (option) => {
    // You can handle the option click here
    console.log(`${option} selected`);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const openOverlay = () => {
    setIsOverlayOpen(true);
    getsavedemails();
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const options =
    wallets?.map((wallet) => ({
      value: wallet.currency,
      label: wallet.currency,
    })) || [];

  const emailoptions = [
    ...savedEmails.map((savedEmail) => ({
      value: savedEmail,
      label: savedEmail,
    })),
    { value: "other", label: "Other" },
  ];

  const handleCurrencyClick = (currency) => {
    // console.log("Updating selectedCurrency to:", currency);
    setSelectedCurrency(currency);
    getsavedemails();
    // console.log("selectedCurrency updated:", currency);
  };

  // console.log(state);

  // useEffect(() => {
  //   if (!state.loading && !state.loged) {
  //     router.push("/");
  //   } else if (state.loged) {
  //     _axios
  //       .get(buildLink("getBalance", undefined, window.innerWidth))
  //       .then((response) => {
  //         if (response.data.success) {
  //           setBalance(response.data.data.balance);
  //         } else {
  //         }
  //         if (!state.loged) {
  //           router.push("/");
  //         }
  //       });
  //   }
  // }, [state.loading]);

  const fetchTransactionHistory = (currency) => {
    setLoading(true);
    _axios
      .get(
        buildLink("getTransactionHistory", undefined, window.innerWidth) +
          `&currency=${currency}&limit=${limit}&page=${page}`
      )
      .then((response) => {
        if (response.data.success) {
          setTransactionData(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transaction history:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCurrency) {
      fetchTransactionHistory(selectedCurrency);
    }
  }, [selectedCurrency, page]);

  function pageSetter(page) {
    setPage(page["selected"] + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const [showMore, setShowMore] = useState(false);
  const [emailPage, setEmailPage] = useState(null);
  const [selectKey, setSelectKey] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false); // Control menu visibility

  const getsavedemails = () => {
    _axios
      .get(buildLink("getsavedemails", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          setSavedEmails(response.data.data.emails);
          setShowMore(response.data.data.show_more);
          setEmailPage(response.data.data.next_page);
        } else {
          console.log("No saved emails found");
        }
      })
      .catch((error) => console.error("Error fetching saved emails:", error));
  };

  const loadMoreEmails = () => {
    if (emailPage) {
      _axios
        .get(buildLink("getsavedemails") + "&page=" + emailPage)
        .then((response) => {
          if (response.data.success) {
            setSavedEmails((prevEmails) => [
              ...prevEmails,
              ...response.data.data.emails,
            ]);
            setShowMore(response.data.data.show_more);
            setEmailPage(response.data.data.next_page);
          } else {
            console.log("No more saved emails");
          }
        })
        .catch((error) => console.error("Error fetching more emails:", error));
    }
  };

  // const emailoptions = savedEmails.map((email) => ({
  //   value: email,
  //   label: email,
  // }));

  const handlesubmitsend = (e) => {
    setLoadingsubmit(true);
    e.preventDefault();

    const obj = {
      email: email,
      remember_receiver: true,
      amount: amount,
      currency_from: sentselectedCurrency,
      currency_to: sentselectedCurrency,
    };

    _axios
      .post(
        buildLink("transferwalletcredit", undefined, window.innerWidth),
        obj
      )
      .then((response) => {
        if (response.data.success) {
          // console.log("Transfer submitted successfully", response);
          toast(response.data.message, { type: "success" });
          fetchBalanceAndWallets();
          setIsOverlayOpen(false);
          setLoadingsubmit(false);
          fetchTransactionHistory(selectedCurrency);
          // window.location.reload();
        } else {
          console.error("Transfer failed", response);
          toast(response.data.errors[0].errorMsg, { type: "error" });
          setLoadingsubmit(false);
        }
      })
      .catch((error) => {
        setLoadingsubmit(false);

        toast("An error occurred.", {
          type: "error",
        });
      });
  };

  const fetchBalanceAndWallets = () => {
    _axios
      .get(buildLink("getBalance", undefined, window.innerWidth) + "&test_n")
      .then((response) => {
        if (response.data.success) {
          setBalance(response.data.data.balance);
          setWallets(response.data.data.wallets); // Corrected this to set the wallets
        }
      })
      .catch((error) => console.error("Error fetching balance:", error));
  };

  useEffect(() => {
    if (!state.loading && !state.loged) {
      router.push("/");
    } else if (state.loged) {
      fetchBalanceAndWallets();
    }
  }, [state.loading]);

  // useEffect(() => {
  //   console.log("selectedCurrency updated:", selectedCurrency);
  // }, [selectedCurrency]);

  // useEffect(() => {
  //   if (selectedCurrency) {
  //     fetchTransactionHistory(selectedCurrency);
  //   }
  // }, [selectedCurrency, page]); // Fetch history when currency or page changes

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    console.log("total pages", transactionData.total_pages);
  }, [transactionData]);
  return (
    <div className="text-dblack flex w-full">
      <div
        className={`w-full mb-4 md:w-[25%]  -ml-3 ${
          width <= 650 ? "hidden" : ""
        }`}
      >
        <UserSidebar active={"wallet"} />
      </div>
      <div className="pb-2 w-full">
        <div className="flex-row w-full md:flex">
          <div className="w-full  px-2 md:px-0  mb-5">
            {loading ? (
              <div className="flex justify-center w-full">
                <PointsLoader />
              </div>
            ) : (
              <div className="w-full  md:mx-auto">
                <div className="relative w-full h-56 md:h-44 flex flex-col gap-3 md:gap-0 md:flex-row justify-between items-start md:items-center p-5 bg-gradient-to-r from-[rgba(204,0,0,1)] to-[rgba(204,0,0,0.5)]">
                  {/* User info section - placed at the start */}
                  <div className="flex flex-row justify-between w-full space-x-8">
                    {/* Left Section */}
                    <div className="flex flex-row gap-5 justify-center">
                      <div className="flex flex-col text-white text-5xl ml-2">
                        Wallet
                      </div>
                      <div className="text-white text-5xl my-auto">
                        <FaWallet />
                      </div>
                    </div>

                    {/* Right Section */}
                    {dataInformation && dataInformation.data && dataInformation.data.use_transfer_wallet_service && (
                      <div className="flex flex-col justify-center items-start space-y-4 my-auto">
                        <Popup
                          trigger={
                            <button>
                              <TbMenu2 className="mt-4 w-10 h-10 text-white" />
                            </button>
                          }
                          position="left bottom"
                        >
                          {(close) => (
                            <div className="flex flex-col p-3 gap-2 bg-white shadow-md w-32">
                              <button
                                onClick={() => {
                                  openOverlay();
                                  close();
                                }}
                                className="flex flex-row text-black gap-3"
                              >
                                <IoIosSend className="my-auto" />
                                <span>Transfer</span>
                              </button>
                            </div>
                          )}
                        </Popup>
                      </div>
                    )}
                  </div>

                  {/* Buttons section */}
                  <div className="max-md:-bottom-44 -bottom-44 absolute inset-0 gap-2 flex justify-center items-center w-fit mx-auto  border-4 border-[#cecece] bg-[#f2f2f2] rounded-md h-fit my-auto cursor-pointer">
                    {wallets.map((wallet, index) => (
                      <button
                        key={index}
                        id={`wallet-${index}`}
                        onClick={() => handleCurrencyClick(wallet.currency)}
                        className={`transition-all duration-300 text-sm px-9 py-1.5 flex justify-start items-center ${
                          selectedCurrency === wallet.currency
                            ? "border-white bg-white text-dblackk rounded-md"
                            : "text-dgreySeller"
                        }`}
                      >
                        <div className="flex items-center">
                          {/* Currency flag */}
                          <div className="mr-2 flex-shrink-0">
                            {currencyToCountry[wallet.currency] && (
                              <Flag
                                code={currencyToCountry[wallet.currency]}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                                alt={`${wallet.currency} flag`}
                              />
                            )}
                          </div>

                          {/* Currency details */}
                          <div>
                            <div className="text-sm font-medium whitespace-nowrap">
                              {wallet.currency} Balance
                            </div>
                            <div
                              className={`text-sm ${
                                wallet.balance < 0
                                  ? "text-dbase"
                                  : wallet.balance > 0
                                  ? "text-dgreen"
                                  : "text-dblackk"
                              }`}
                            >
                              {wallet.balance ? wallet.balance : "0.00"}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>

                {isOverlayOpen && (
                  <div>
                    {/* Black Overlay Background with Padding */}
                    <div
                      className="fixed inset-0 bg-dblackOverlay bg-opacity-50 z-40 transition-opacity p-10"
                      onClick={() => {
                        closeOverlay();
                        console.log("close overlay");
                      }}
                    ></div>

                    {/* Centered Content with padding */}
                    <div className="fixed inset-0 flex items-center justify-center z-50 p-8">
                      <div className="w-1/3 max-sm:w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl mb-4 text-center">
                          Transfer to Another Account
                        </h2>

                        <form onSubmit={handlesubmitsend} className="space-y-4">
                          <div className="mb-4">
                            <label
                              htmlFor="amount"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Amount
                            </label>
                            <input
                              type="number"
                              id="amount"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              required
                            />
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="currency"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Currency
                            </label>
                            <Select
                              onChange={(selectedOption) =>
                                setSentSelectedCurrency(
                                  selectedOption ? selectedOption.value : null
                                )
                              }
                              value={options.find(
                                (option) =>
                                  option.value === sentselectedCurrency
                              )}
                              options={options}
                              className="rounded-md text-center text-dblackk"
                              placeholder="Select Currency"
                              isDisabled={options.length === 0}
                              noOptionsMessage={() => "No currencies available"}
                            />
                          </div>

                          {savedEmails && savedEmails.length > 0 ? (
                            <div className="mb-4">
                              <label
                                htmlFor="saved-email"
                                className="block text-sm font-medium text-dblack"
                              >
                                Choose From Saved Emails or Select "Other"
                              </label>
                              <Select
                                id="saved-email"
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === "other") {
                                    setShowEmailInput(true);
                                    setMenuIsOpen(false);
                                  } else if (
                                    selectedOption.value === "load_more"
                                  ) {
                                    loadMoreEmails();
                                    setMenuIsOpen(true);
                                  } else {
                                    setEmail(selectedOption.value);
                                    setShowEmailInput(false);
                                    setMenuIsOpen(false);
                                  }
                                }}
                                value={
                                  showEmailInput
                                    ? { value: "other", label: "Other" }
                                    : emailoptions.find(
                                        (option) => option.value === email
                                      )
                                }
                                options={[
                                  ...emailoptions,
                                  ...(showMore
                                    ? [
                                        {
                                          value: "load_more",
                                          label: "Load More",
                                        },
                                      ]
                                    : []),
                                ]}
                                className="rounded-md text-center"
                                placeholder="Select an email"
                                noOptionsMessage={() => "No emails available"}
                                menuIsOpen={menuIsOpen}
                                onMenuOpen={() => setMenuIsOpen(true)}
                                onMenuClose={() => setMenuIsOpen(false)}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                  }),
                                  menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                  }),
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  required
                                  placeholder="Enter Receiver Email Address"
                                />
                              </div>

                              <div className="mb-4 flex items-center justify-start space-x-2">
                                <input
                                  type="checkbox"
                                  id="rememberEmail"
                                  checked={rememberEmail}
                                  onChange={() =>
                                    setRememberEmail(!rememberEmail)
                                  }
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  htmlFor="rememberEmail"
                                  className="text-sm text-gray-700"
                                >
                                  Remember Email
                                </label>
                              </div>
                            </div>
                          )}

                          {showEmailInput && (
                            <>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  required
                                  placeholder="Enter Receiver Email Address"
                                />
                              </div>

                              <div className="mb-4 flex items-center justify-start space-x-2">
                                <input
                                  type="checkbox"
                                  id="rememberEmail"
                                  checked={rememberEmail}
                                  onChange={() =>
                                    setRememberEmail(!rememberEmail)
                                  }
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  htmlFor="rememberEmail"
                                  className="text-sm text-gray-700"
                                >
                                  Remember Email
                                </label>
                              </div>
                            </>
                          )}

                          <div className="flex justify-end space-x-4">
                            <button
                              className="bg-dbase text-white px-6 py-2 rounded-md"
                              onClick={closeOverlay}
                            >
                              Close
                            </button>

                            <button
                              type="submit"
                              className="bg-dbase text-white px-6 py-2 rounded-md"
                            >
                              {Loadingsubmit ? (
                                <div className="w-6 h-6 ">
                                  <Loader />
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}

                <div className="  mt-10">
                  {selectedCurrency === "SERVICES" ? (
                    <div className="flex flex-col md:flex-row gap-8"></div>
                  ) : transactionData?.data_transactions?.length === 0 ? (
                    <div className="flex flex-col items-center justify-center text-center py-8">
                      <h2 className="text-xl text-gray-600">
                        You don't have any transactions yet.
                      </h2>
                      <p className="text-gray-400">
                        Start by making your first transaction!
                      </p>
                    </div>
                  ) : (
                    transactionData?.data_transactions?.map((data) => (
                      <div
                        key={data.transaction_id}
                        className="bg-white rounded-md shadow-md mb-3 p-4"
                      >
                        <div className="flex items-center justify-between mb-3">
                          <div className="flex items-center gap-2">
                            <div
                              className="rounded-full p-4 text-sm"
                              style={{
                                backgroundColor: "#ffffff",
                                border: "1px solid red",
                              }}
                            >
                              {firstname
                                .replace(/\s+/g, "")
                                .charAt(0)
                                .toUpperCase()}{" "}
                              {lastname
                                .replace(/\s+/g, "")
                                .charAt(0)
                                .toUpperCase()}
                            </div>
                            <div>
                              <div className="capitalize">
                                {firstname} {lastname}
                              </div>
                              <div className="text-sm text-dgreyQtyProduct">
                                {data.date}
                              </div>
                            </div>
                          </div>
                          <div className="pr-semibold text-dgreyProduct">
                            {data.type}
                          </div>
                        </div>
                        <div>
                          Amount:{" "}
                          <span
                            className={`${
                              data.isDeposit ? "text-dgreen" : "text-dbase"
                            }`}
                          >
                            {data.amount}
                          </span>
                        </div>
                        {data.description.length > 0 && (
                          <div>
                            Description:{" "}
                            <span className="text-dgreyProduct">
                              {data.description}
                            </span>
                          </div>
                        )}
                      </div>
                    ))
                  )}

                  <div className="pagination-div pt-4">
                    {!loading &&
                      transactionData?.total_transactions > limit && (
                        <ReactPaginate
                          pageCount={transactionData?.total_pages} // Use total_pages directly
                          containerClassName={"category-pagination"}
                          onPageChange={pageSetter}
                          pageRangeDisplayed={width > 650 ? 2 : 1}
                          marginPagesDisplayed={width > 650 ? 1 : 1}
                          previousLabel={<IoIosArrowBack />}
                          previousLinkClassName={"arrowLink"}
                          nextLinkClassName={"arrowLink"}
                          nextLabel={<IoIosArrowForward />}
                          activeClassName={"active-pagination-category"}
                          forcePage={page - 1}
                        ></ReactPaginate>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wallet;
