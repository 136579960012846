import _axios from "../axios";
import Loader from "../components/Loader";
import PointsLoader from "../components/PointsLoader";
import { CurrencyContext } from "../contexts/CurrencyContext";
import buildLink from "../urls";
import { useNavigate } from "react-router";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FaSms, FaTrash } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { IoWarning } from "react-icons/io5";
import { MdOutlineVerified, MdVerified } from "react-icons/md";
import { toast } from "react-toastify";
import { AccountContext } from "../contexts/AccountContext";
import UserSidebar from "../components/UserSidebar";
import { useWindowDimensions } from "../components/TopHeader";
function ProfileTest() {
  const { width } = useWindowDimensions();
  const [state, dispatch] = useContext(AccountContext);
  const [emailNotif, setEmailNotif] = useState([]);
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [accountInfo, setAccountInfo] = useState(null);
  const dataInformation = useContext(CurrencyContext);
  const navigate = useNavigate();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const date_of_birth = useRef(null);
  const [disabledEmails, setDisabledEmails] = useState([]);
  const oldPassRef = useRef(null);
  const newPassRef = useRef(null);
  const confirmPassRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  let verificationCode = ["", "", "", "", "", ""];
  const [validateCodeConst, setValidateCodeConst] = useState(0);
  const verificationCodeRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const [codeSection, setCodeSection] = useState(false);
  let isPast = false;
  let code = "";
  // const [disabledEmails, setDisabledEmails] = useState([]);
  useEffect(() => {
    // setLoadingAccount(true);
    if (!state.loading && !state.loged) {
      navigate("/");
      // setLoadingAccount(false);
    } else if (state.loged) {
      getAccount();

      //get email notifications
    }
  }, [state.loading]);

  async function getAccount() {
    setLoadingAccount(true);
    _axios
      .get(buildLink("get_account", undefined, window.innerWidth))
      .then((response) => {
        if (!response.data.success) {
          setLoadingAccount(false);
        } else {
          setAccountInfo(response.data.data);

          _axios
            .get(buildLink("EmailNotifications", undefined, window.innerWidth))
            .then((res) => {
              // console.log(res);
              setLoadingAccount(false);
              if (res.data.success === true) {
                setEmailNotif(res.data.promotion_email);
                res.data.promotion_email.map((em) => {
                  if (em.disable === 1) {
                    setDisabledEmails((current) => [
                      ...current,
                      em.service_key,
                    ]);
                  }
                });
              }
            });
        }
      });
  }
  function saveDisabledEmails() {
    setLoadingAccount(true);
    const obj = {
      discard_email: disabledEmails,
    };
    _axios
      .post(
        buildLink("disableEmailNotification", undefined, window.innerWidth),
        obj
      )
      .then((res) => {
        setLoadingAccount(false);
        if (res.data.success) {
          toast("Saved succesfuly", { type: "success" });
        } else {
          toast("wrong !", { type: "error" });
        }
      });
  }

  async function saveDetails(e) {
    e.preventDefault();
    const first = firstNameRef.current.value;
    const last = lastNameRef.current.value;
    const birthDate = date_of_birth.current.value;
    const obj = {
      firstname: first,
      lastname: last,
      email: emailRef.current.value,
      date_of_birth: birthDate,

      telephone: phoneRef.current.value,
    };
    if (obj.telephone == accountInfo.telephone) {
      obj.verified = 1;
    } else {
      obj.verified = 0;
    }
    // setAlert(true);

    setLoadingAccount(true);
    await _axios
      .put(buildLink("save_account", undefined, window.innerWidth), obj)
      .then((response) => {
        if (response.data.success) {
          toast("Information saved successfuly", { type: "success" });
          dispatch({ type: "setUsername", payload: first });

          getAccount();

          if (
            dataInformation.show_checkin &&
            response.data.points_gained &&
            response.data.points_gained > 0
          ) {
            dispatch({
              type: "setEarnedPointInfo",
              payload: {
                message: response.data?.points_message
                  ? response.data.points_message
                  : "",
                earnedPoint: response.data?.points_gained,
              },
            });
          }
        } else {
          toast(response.data.errors[0].errorMsg, { type: "error" });
          setLoadingAccount(false);
        }
      })
      .catch((err) => {
        setLoadingAccount(false);
      });
  }

  async function disabledAccount() {
    setLoadingAccount(true);
    _axios
      .post(buildLink("disabledAccount", undefined, window.innerWidth))
      .then((response) => {
        setLoadingAccount(false);
        if (response.data.success) {
          //   logout();
          window.location.href = "/";
        }
      });
  }

  // async function logout() {
  //   dispatch({ type: "setLoading", payload: true });

  //   const hostname = window.config["site-url"];
  //   //remove next-auth session from cookie, and clear the jwt(session) obj.
  //   await signOut({ redirect: false });
  //   //Logout from Api
  //   _axios
  //     .post(buildLink("logout", undefined, undefined, hostname))
  //     .then(async (res) => {
  //       if (res.data.success) {
  //         await checkLogin();
  //       }
  //     });
  // }

  function ChangePassword(e) {
    e.preventDefault();

    const obj = {
      old_password: oldPassRef.current.value,
      new_password: newPassRef.current.value,
      confirm: confirmPassRef.current.value,
    };

    if (obj.new_password === obj.confirm && obj.new_password !== "") {
      setLoadingAccount(true);
      _axios
        .put(buildLink("change_password", undefined, window.innerWidth), obj)
        .then((response) => {
          setLoadingAccount(false);
          if (response.data.success) {
            oldPassRef.current.value = "";
            newPassRef.current.value = "";
            confirmPassRef.current.value = "";
            toast("Password changed succussfuly", { type: "success" });
          } else {
            toast(response.data.message, { type: "error" });
          }
        });
    } else {
      toast("Error changing password", { type: "error" });
    }
  }
  async function checkLogin() {
    dispatch({ type: "setLoading", payload: true });
    await _axios
      .get(
        buildLink("login", undefined, undefined, window.config["site-url"]) +
          `&type=logout`
      )
      .then((response) => {
        // console.log(response);

        dispatch({ type: "setLoged", payload: false });

        dispatch({ type: "setLoading", payload: false });
        navigate("/");

        window.location.reload();
      });

    // window.location.reload();
  }
  function disableEmailNotification(e, index, service_key) {
    const input = document.getElementById("check" + index);
    if (!input.checked) {
      // if (!disabledEmails.includes(service_key)) {
      setDisabledEmails(disabledEmails.concat(service_key));
      //}
    } else {
      setDisabledEmails(disabledEmails.filter((dis) => dis !== service_key));
    }
  }

  async function CustomerPhoneVerificatio() {
    const obj = {
      customer_id: state.userId,
    };
    setLoadingVerify(true);
    await _axios
      .post(buildLink("CustomerPhoneVerification"), obj)
      .then((res) => {
        if (res.data.success) {
          setCodeSection(true);
          code = res.data.code;
          verificationCodeRefs[0].current.focus();
          setLoadingVerify(false);
        } else {
          setOpenModal(false);
          setLoadingVerify(false);
        }
      });
  }
  const handlePasteCode = (e) => {
    isPast = true;
    const pastedCode = e.clipboardData.getData("text/plain");
    // console.log(pastedCode);

    // Validate that the pasted code is numeric and has the correct length
    if (/^\d{6}$/.test(pastedCode)) {
      const newVerificationCode = pastedCode
        .split("")
        .slice(0, verificationCode.length);
      verificationCodeRefs.map(
        (item, index) => (item.current.value = newVerificationCode[index])
      );
      handleVerifyCode();
    }
  };

  const handleVerifyCode = async () => {
    verificationCodeRefs.map(
      (item, index) => (verificationCode[index] = item.current.value)
    );

    const code = verificationCode.join("");

    if (code.toString().length === 6) {
      const obj = {
        customer_id: state.userId,
        verification_code: code,
      };
      setLoadingVerify(true);

      await _axios
        .post(buildLink("CustomerPhoneVerification"), obj)
        .then((res) => {
          setLoadingVerify(false);

          if (res.data.success) {
            getAccount();
            setCodeSection(false);
            setOpenModal(false);
          } else {
            toast(res.data.message, { type: "error" });
          }
        });
    } else {
      // toast('Please enter a 6 digit code', { type: 'error' });
    }
  };

  const handleCodeChange = (index, value) => {
    if (!isPast) {
      verificationCodeRefs[index].current.value = value;
      if (value !== "" && index < verificationCode.length - 1) {
        verificationCodeRefs[index + 1].current.focus();
      }
      handleVerifyCode();
    }
  };
  return (
    <div className="container flex flex-row text-dblack  ">
      <div
        className={`w-full mb-4 md:w-1/5  -ml-3 ${
          width <= 650 ? "hidden" : ""
        }`}
      >
        <UserSidebar active={"addresses"} />
      </div>
      <header>
        <title>My Account | ishtari</title>
      </header>
      {accountInfo ? (
        <div className=" flex flex-col gap-2 ">
          <div className=" flex flex-col  ">
            <h2 className=" text-3xl  pr-semibold">Profile</h2>
            <p className=" text-dgreySeller">
              Manage your profile informations
            </p>
          </div>

          <form
            onSubmit={(e) => saveDetails(e)}
            className=" mt-10 py-5 px-10 max-md:px-1 rounded-lg bg-white flex flex-col gap-3 w-full h-full "
          >
            <h2 className=" pr-semibold text-md">General </h2>
            <div className=" flex flex-row gap-5 justify-start w-full">
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">First Name </label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-col w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={firstNameRef}
                    defaultValue={accountInfo.firstname}
                    placeholder="Enter your first name"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">Last Name </label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={lastNameRef}
                    defaultValue={accountInfo.lastname}
                    placeholder="Enter your last name"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>
            </div>
            <div className=" flex flex-row gap-5 max-md:flex-col justify-start w-full">
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">Email</label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={emailRef}
                    defaultValue={accountInfo.email}
                    placeholder="Enter your email"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>

              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">
                  Phone Number{" "}
                  {!accountInfo.telephone && (
                    <span className=" text-dbase   pr-semibold">
                      {" "}
                      ( Required ) *
                    </span>
                  )}
                </label>
                <div
                  className={`   ring-opacity-25 ${
                    accountInfo.verified ? "" : "ring-dbase1  ring-4"
                  }  relative overflow-hidden border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25   rounded-md `}
                >
                  <input
                    ref={phoneRef}
                    defaultValue={accountInfo.telephone}
                    placeholder="Enter your phone number"
                    className=" my-auto custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                  {accountInfo.verified ? (
                    <div className="  w-20 h-full  py-3  bg-dgreen   transition-all flex justify-center">
                      <MdVerified className=" text-white" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setOpenModal(true)}
                      className=" w-20 h-full  py-2  bg-[#e4e4e4]  text-dbase   hover:bg-dbase hover:text-white transition-all flex justify-center "
                    >
                      {" "}
                      <div className=" relative ">Verify</div>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className=" flex flex-row gap-5 justify-start w-full">
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">Date of birth</label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-2  rounded-md ">
                  <input
                    disabled={!accountInfo.date_of_birth == ""}
                    ref={date_of_birth}
                    type="date"
                    defaultValue={accountInfo.date_of_birth}
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <span className=" text-sm text-dbase">
                  You can update your birth date only once !
                </span>
              </div>
              <div className=" flex flex-col    justify-center     ">
                <button className="   w-24 h-fit py-2 bg-dblue hover:opacity-90 transition-all text-white text-lg rounded-lg">
                  Save{" "}
                </button>
              </div>
            </div>
          </form>

          <form
            onSubmit={ChangePassword}
            className=" mt-10 py-5 px-10 max-md:px-1 rounded-lg bg-white flex flex-col gap-3 w-full h-full "
          >
            <h2 className=" pr-semibold text-md">Security </h2>

            <div className=" flex flex-row gap-5 justify-start w-full">
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">Old Password</label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={oldPassRef}
                    type="password"
                    placeholder="Enter old password"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">New Password</label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={newPassRef}
                    type="password"
                    placeholder="Enter your new password"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>
            </div>

            <div className=" flex flex-row gap-5 justify-start w-full">
              <div className=" flex flex-col w-full">
                <label className=" text-dlabelColor ">
                  Confirm New Password
                </label>
                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#f2f2f2] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={confirmPassRef}
                    type="password"
                    placeholder="Confirm your new password"
                    className="  custom-cursor-color placeholder:text-[#cecece] w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
              </div>
              <div className=" flex flex-col    justify-end     ">
                <button className="   w-24 h-fit py-2 bg-dblue hover:opacity-90 transition-all text-white text-lg rounded-lg">
                  Save{" "}
                </button>
              </div>
            </div>
          </form>

          <div className=" mt-10 py-5 px-10 max-md:px-1 rounded-lg bg-white flex flex-col gap-3 w-full h-full ">
            <h2 className=" pr-semibold text-md">Email Notifications </h2>
            <div className=" flex flex-col gap-4">
              {emailNotif?.map((email, index) => (
                <div
                  className="flex  border border-[#d5d5d5] border-opacity-25 rounded-lg bg-[#f2f2f2] bg-opacity-25   py-3 px-5 justify-between text-sm"
                  key={email.service_key}
                >
                  <div className="  text-sm  pr-semibold text-dlabelColor ">
                    {email.service_title}
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id={"check" + index}
                      defaultChecked={!email.disable ? "checked" : ""}
                      onChange={(e) =>
                        disableEmailNotification(e, index, email.service_key)
                      }
                    />
                    <span className="slider_swicth round"></span>
                  </label>
                </div>
              ))}
              <div className=" w-full mt-2 flex flex-row justify-end ">
                <button
                  onClick={() => saveDisabledEmails()}
                  className=" w-24 py-2 bg-dblue hover:opacity-90 transition-all text-white text-lg rounded-lg"
                >
                  Save{" "}
                </button>
              </div>
            </div>
          </div>

          <div className=" mt-10 py-5 px-10 max-md:px-1 rounded-lg bg-white flex flex-col gap-3 w-full h-full ">
            <h2 className=" pr-semibold text-md">Delete Account</h2>
            <div className=" flex flex-row  max-md:flex-col gap-2 justify-between w-full">
              <p className=" my-auto text-dlabelColor text-sm">
                Deleting your account is permanent and cannot be undone. You
                will no longer be able to log in or recover your account.{" "}
              </p>
              <button
                onClick={() => disabledAccount()}
                className="  py-2 bg-dbase flex flex-row gap-2 hover:opacity-90 justify-center px-10 transition-all text-white text-lg rounded-lg"
              >
                {" "}
                <FaTrash className=" my-auto" />
                Delete Account{" "}
              </button>
            </div>
          </div>
          {loadingAccount && (
            <div className="  bg-dblackOverlay w-full  h-full fixed inset-0 z-50 justify-center flex ">
              <div className=" h-fit my-auto w-16 relative">
                <Loader />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className=" w-full flex justify-center text-center">
          <PointsLoader />
        </div>
      )}

      {openModal && (
        <div
          onClick={() => setOpenModal(false)} // Closes the modal when the overlay is clicked
          className="fixed inset-0 z-50 bg-dblack bg-opacity-50 flex items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()} // Prevents modal clicks from closing the overlay
            className={`${
              openModal
                ? "top-0 overflow-hidden scale-100 opacity-100"
                : "-top-32 scale-0 opacity-0"
            } z-50 fixed px-5 rounded-md max-md:w-[350px] max-md:h-[350px] left-0 top-0 bottom-0 py-5 right-0 transition-opacity duration-300 bg-white my-auto mx-auto w-[450px] h-[350px] shadow-md shadow-dgrey1`}
          >
            <div className="w-full relative">
              <div className="w-fit py-4 mx-auto">
                <FaSms className="text-[70px] text-dbase" />
              </div>
              <div
                className={`${
                  codeSection ? "right-[500px]" : "right-0 md:right-14 "
                } absolute transition-all `}
              >
                <div className="w-full h-full  relative justify-center text-center flex flex-col gap-4">
                  <div className="mx-auto">
                    <h2 className="font-bold text-2xl">
                      Send Verification Code
                    </h2>
                    <span className="mt-4">
                      Are you sure you want to verify the number{" "}
                      {dataInformation.telephone}
                    </span>
                    <button
                      onClick={() => CustomerPhoneVerificatio()}
                      className="mt-8 w-full border border-dbase rounded-md text-dbase py-3 bg-white hover:bg-dbase1 flex flex-row justify-center gap-5 hover:text-white transition-all"
                    >
                      <span>Send </span>
                      {loadingVerify && (
                        <div className="w-6 h-6">
                          <Loader />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  codeSection ? "left-0" : "left-[500px]"
                } absolute w-full h-full transition-all`}
              >
                <div className="flex justify-center text-center">
                  <div className="my-auto w-full mx-auto">
                    <h2 className="font-bold text-2xl">
                      Phone Number Validation
                    </h2>
                    <span className="mt-4">
                      Enter the code sent to {dataInformation.telephone}
                    </span>
                    <div className="w-fit mx-auto gap-2 mt-5 flex flex-row">
                      {verificationCode.map((digit, index) => (
                        <input
                          className="w-10 py-2 text-center bg-dgrey rounded-md border-b border-dbase"
                          key={index}
                          type="text"
                          maxLength="1"
                          onChange={(e) =>
                            handleCodeChange(index, e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Backspace" && index > 0) {
                              if (e.target.value === "") {
                                verificationCodeRefs[index - 1].current.focus();
                              } else {
                                verificationCodeRefs[index].current.value = "";
                              }
                            }
                          }}
                          onPaste={(e) => handlePasteCode(e)}
                          ref={verificationCodeRefs[index]}
                        />
                      ))}
                    </div>
                    <div className="w-full flex flex-row gap-2">
                      <button
                        onClick={() => handleVerifyCode()}
                        className="mt-8 w-full border border-dbase rounded-md text-dbase py-3 bg-white hover:bg-dbase1 flex flex-row justify-center gap-5 hover:text-white transition-all"
                      >
                        <span>Submit </span>
                        {loadingVerify && (
                          <div className="w-6 h-6">
                            <Loader />
                          </div>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setOpenModal(false);
                          setCodeSection(false);
                        }}
                        className="mt-8 w-full border border-dbase rounded-md text-white py-3 bg-dbase hover:bg-dbase1 transition-all"
                      >
                        <span>Cancel </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileTest;
