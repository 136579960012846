import { useContext, useEffect, useState } from "react";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import { WishlistContext } from "../contexts/WishlistContext";
import buildLink, { path } from "../urls";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { FiHeart } from "react-icons/fi";

function TopWishlist() {
  const [state, dispatch] = useContext(WishlistContext);
  const [accountState] = useContext(AccountContext);

  useEffect(() => {
    dispatch({
      type: "loading",
      payload: true
    });

    // _axios
    //   .get(buildLink("wishlist", undefined, window.innerWidth))
    //   .then((response) => {
    // if (response.data.success) {
    //   dispatch({
    //     type: "setProducts",
    //     payload: response.data.data.products
    //   });
    //   dispatch({
    //     type: "setTotals",
    //     payload: response.data.data.totals
    //   });
    //   const ids = response.data.data.products.map((p) => p.product_id);
    //   dispatch({
    //     type: "setProductIds",
    //     payload: ids
    //   });
    //   dispatch({
    //     type: "loading",
    //     payload: false
    //   });
    // }
    // alert(accountState.loged)
    // if(accountState.loged){
    _axios
      .get(buildLink("wishlistCount", undefined, window.innerWidth))
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "setProductsCount",
            payload: response.data.data.total
          });
          dispatch({
            type: "setProductIds",
            payload: response.data.data.products
          });
          dispatch({
            type: "loading",
            payload: false
          });
        } else {
          dispatch({
            type: "setProductsCount",
            payload: response.data.data?.total
          });
          dispatch({
            type: "loading",
            payload: false
          });
        }
      });
      
    // }
    // });
  }, [dispatch]);

  return accountState.loged ? (
    <Link
      className={`${
        window.config["showCart"] ? "block" : "hidden"
      } text-white xl:border-r border-dmenusep max-md:hidden font-semibold text-base flex items-center h-8 pl-2 pr-3 sm:pl-3 sm:pr-6 cursor-pointer hover:opacity-80 relative`}
      to={`${path}/account/wishlist`}
    >
      <span className="hidden xl:block lg:block">Wishlist</span>
      <FiHeart
        className={`ml-2 w-4  ${
          window.innerWidth < 650 ? "text-dgreyBlack h-4" : "text-white h-4"
        }`}
      />

      <span
        className={`w-5 h-5  bg-dblue flex  items-center justify-center rounded-full text-xs absolute right-0.5 sm:right-2 -top-0.5 sm:-top-1 border border-white ${
          window.innerWidth < 650 ? "-mr-0.5" : "mr-1"
        }`}
      >
        <span>
          {state.loading ? (
            <Loader styles={"h-4 w-4 text-white"} />
          ) : (
            <span>{state.productsCount}</span>
          )}
        </span>
      </span>
    </Link>
  ) : (
    <div></div>
  );
}

export default TopWishlist;
