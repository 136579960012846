import { createContext, useState } from "react";

export const ExportProductContext = createContext();

export const ExportProductProvider = ({ children }) => {
  const [exportProduct, setExportProduct] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [errPermission, setErrPermission] = useState("");

  return (
    <ExportProductContext.Provider
      value={{
        exportProduct,
        setExportProduct,
        loadingExport,
        setLoadingExport,
        errPermission,
        setErrPermission,
      }}
    >
      {children}
    </ExportProductContext.Provider>
  );
};
