
import SeoHandler from "../components/SeoHandler"

function Failed(props) {
 
    return (
        <div className="bg-dbase fixed top-0 left-0 w-screen h-screen flex justify-center items-center text-white flex-col">
            <span className="  w-20 h-20 bg-white flex items-center justify-center mb-4 rounded-full">
                <i className="icon icon-cancel text-5xl text-dbase"></i>
                <SeoHandler data={{ title: "Failed " }} />
            </span>
            <h1 className="font-bold text-3xl">Your Payment Didnt Complete please try again </h1>
            <button onClick={() => window.location.href = "/"} className="bg-white text-dbase px-10 py-3 rounded mt-4 font-bold">Home</button>
        </div>
    )
}

export default Failed