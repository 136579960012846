// import right from '../assets/images/right-arrow.png'
// import left from '../assets/images/left-arrow.png'
import right from '../assets/images/arrows-02.png'
import left from '../assets/images/arrows-01.png'

export default function CustomArrows({direction,onClick,style,className}) {
    return (

        <div style={{...style , padding:"2px 5px"}} onClick={onClick} className={`${className} absolute z-20 ${direction === 'r' ? 'right-0' : 'left-0'}  rounded-full w-14 h-14  top-1/2  cursor-pointer `}>
            <img src={direction === 'r' ? right : left} alt="" className='w-16 h-16 transform rounded-full hover:scale-125 ease-in-out' />
        </div>
    )
}


